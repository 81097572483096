<template>
    <div class="main">
        <LeftMenu></LeftMenu>
        <section class="container">
            <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">Role</p>
                </div>
            </section>
            <hr>
            <!-- {{ maserClients.data }} -->
              <div v-if="roleStore.loading">
                <p>Data is loading....</p>
              </div>
            <section class="container">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <p>Role lists</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <p class="level-item"><router-link :to="'/role/create/'" class="button is-primary">Add</router-link></p>
                    </div>
                </nav>
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="roleStore.role">
                        <tr v-for="(role, index) in roleStore.role" :key="role.id">
                            <td>{{ serialNumber(index, roleStore.current_page) }}</td>
                            <td>{{ role.name }}</td>
                            <td>
                                <div class="buttons">
                                    <router-link :to="'/role/view/' + role.id" class="button is-info">View</router-link>
                                    <!-- <button class="button is-info">View</button> -->
                                    <!-- <button class="button is-warning">Edit</button> -->
                                    <router-link :to="'/role/edit/' + role.id" class="button is-warning">Edit</router-link>
                                    <button class="button is-danger">Delete</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </section>
        </section>        
    </div>
</template>
<script>

import { useRoleStore } from '@/store/roleStore'
export default {
    setup() {
        const roleStore = useRoleStore()
        roleStore.getRoles()
        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }
        return { roleStore, serialNumber }
    }
}
</script>