import CreateRequirement from '@/views/requirement/CreateRequirement.vue'
import EditRequirement from '@/views/requirement/EditRequirement.vue'
import ListRequirement from '@/views/requirement/ListRequirement.vue'
import ViewRequirement from '@/views/requirement/ViewRequirement.vue'
// import AddRequirement from '@/views/requirement/CreateRequirement.vue'
// import EditUser from '@/views/admin/user/EditUser.vue'

export default [
  {
    path: '/job',
    name: 'job',
    component: ListRequirement,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/requirement/view/:code',
    name: 'view-requirement',
    component: ViewRequirement,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/requirement/create',
    name: 'create-requirement',
    component: CreateRequirement,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/requirement/edit/:code',
    name: 'edit-requirement',
    component: EditRequirement,
    meta: {
      requiresAuth: true
    },
  },
  
]
