<template>
    <div>
        <!-- <LeftMenu></LeftMenu> -->
        <section class="container">
            <section class="container">
                <section v-if="client" class="hero is-link">
                    <div class="hero-body">
                        <h1>Add</h1>
                    </div>
                </section>
                <div>
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit" class="form-container">
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.name" placeholder="Enter the name of the client">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Mobile</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.mobile" placeholder="Enter mobile number">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Email</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.email" placeholder="Enter email">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Contact Person</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.contactPerson" placeholder="Enter contact person name">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Address Line 1</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.addressLine1" placeholder="Enter the address">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Address Line 2</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.addressLine2" placeholder="Enter the address">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">City</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.city" placeholder="Enter the city">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">State</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.state" placeholder="Enter the state">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Pin</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.pin" placeholder="Enter the pin">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Gst</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.gst" placeholder="Enter the gst">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Select Master Client</label>
                            <div class="control">
                                <div class="select">
                                    <select v-model="form.master_client_id">
                                        <option value="">Select Master</option>
                                        <option v-for="masterClient in masterClientStore.masterClient" :key="masterClient.id" :value="masterClient.id">{{ masterClient.name  }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                       
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
// import LeftMenu from '@/components/common/left-menu.vue'
import { useMasterClientStore } from '@/store/masterClientStore'
// import { from } from 'core-js/core/array'
export default {
    // components: {
    //     LeftMenu
    // },
    setup() {
        const masterClientStore = useMasterClientStore()
        masterClientStore.getMasterClients()
        const state = reactive ({
            client: [],
            success: ''
        })
        const form = reactive({
            name: '',
            mobile: '',
            email: '',
            contactPerson: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pin: '',
            gst: '', 
            master_client_id: ''
        })
        const route = useRoute()
        const router = useRouter()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        const handleSubmit = async () => {
            try {
                console.log(route.params.code);
                const response = await axios.post(`${api_url}/api/master-client/client/create`, form)
                if(response.status == 201)
                {
                    state.success = 'Data has been added successfully'
                    router.push('/client')
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Client:', error)
            }
        }

        return { form, handleSubmit, error, state, masterClientStore }
    }
}
</script>