<template>
    <!-- <div class="main"> -->
        
        <section class="container">
            <section class="hero is-light">
                <div class="hero-body">
                    <p class="title">ProAct Lead Management</p>
                    <p class="subtitle">Manage your jobs and candidates follow-up</p>
                </div>
            </section>
            <hr>
            <section class="container">
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                1
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                2
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                3
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                4
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    <!-- </div> -->
    
</template>
<script>
// import LeftMenu from '../../components/common/left-menu.vue'
export default {
    
}
</script>