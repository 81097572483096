<template>
    <div>
        <section class="container">
            <!-- <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">Candidate</p>
                </div>
            </section> -->
            <hr>
            <!-- {{ maserClients.data }} -->
            <div v-if="candidateStore.loading">
            <p>Data is loading....</p>
            </div>
            <section class="container">
                
                <div class="columns">
                    <div class="column is-multiline is-mobile">
                        <div class="box">
                            <nav class="level">
                                <div class="level-left">
                                    <div class="level-item">
                                        <p>Candidate lists</p>
                                    </div>
                                </div>
                                <div class="level-right">
                                    <p class="level-item"><router-link :to="'/candidate/create/'" class="button is-primary">Add</router-link></p>
                                </div>
                            </nav>
                            <hr>
                            <nav class="level">
                                <div class="level-left">
                                    <div class="field is-grouped">
                                        <div class="control">
                                            <input type="text" v-model="state.filter.name" class="input" placeholder="Search by Name" />
                                        </div>
                                        <div class="control select">
                                            <select v-model="state.filter.status">
                                                <option value="null">Select Status</option>
                                                <option value="1">Assigned</option>
                                                <option value="2">Shortlisted</option>
                                                <option value="3">Hired</option>
                                            </select>
                                        </div>
                                        <div class="control">
                                            <multiselect v-if="state.skills" v-model="state.filter.skills"
                                                tag-placeholder="Add this as new Skill" placeholder="Search or add a skill"
                                                label="name" track-by="id" :options="state.skills" :multiple="true"
                                                :taggable="true" :loading="true" @search-change="searchSkill">
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="level-right">                                    
                                    <button class="button is-link" @click="state.toggleModalForImport = !state.toggleModalForImport">Import</button>
                                </div>
                            </nav>
                            <hr>
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th>Sno</th>
                                        <th>Name</th>
                                        <th>Destination</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="candidateStore?.candidate?.length">
                                    <tr v-for="(candidate, index) in candidateStore.candidate" :key="candidate.id">
                                        <td>{{ serialNumber(index, candidateStore.current_page) }}</td>
                                        <td>{{ candidate.name }}</td>
                                        <td>{{ candidate.job_title }}</td>
                                        <td>{{ status(candidate) }}</td>
                                        <td>
                                            <div class="buttons is-justify-content-flex-end">
                                                
                                                <!-- <CandidateFollowUp
                                                    :candidate="candidate"
                                                    :authStore="authStore"
                                                ></CandidateFollowUp> -->
                                                <router-link :to="'/candidate/view/' + candidate.code" class="button is-info">View</router-link>
                                                <router-link :to="'/candidate/edit/' + candidate.code" class="button is-warning">Edit</router-link>
                                                <button class="button is-danger">Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5">
                                            <ListPagination
                                                v-if="candidateStore.pagination"
                                                :links="candidateStore.pagination.links"
                                                :current_page="candidateStore.pagination.current_page"
                                                :last_page="candidateStore.pagination.last_page"
                                                @page-changed="onPageChanged"
                                            />

                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <!-- <ListCandidate v-if="candidateStore?.candidate?.length"
                            :candidates="candidateStore?.candidate" :pagination="candidateStore?.pagination"
                            @page-changed="onPageChanged"
                            ></ListCandidate> -->
                            <CandidateImport 
                                :toggleModalForImport="state.toggleModalForImport" 
                                :authStore="authStore"></CandidateImport>
                            
                        </div>
                    </div>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>

import { onMounted, reactive, watch } from 'vue';
import { useCandidateStore } from '@/store/candidateStore'
import CandidateImport from '@/components/Candidate/Import.vue';
import Multiselect from 'vue-multiselect';
import axios from 'axios'
import { useAuthStore } from '@/store/auth';
// import CandidateFollowUp from '@/components/Candidate/FollowUp.vue'
import ListPagination from '@/components/common/listPagination.vue';
export default {
    components: {
        Multiselect, ListPagination, CandidateImport
    },
    setup() {
        const authStore = useAuthStore()

        const state = reactive({
            skills: [],
            selectedSkills: [],
            filter: {
                name: null,
                status: null,
                skills: []
            },
            toggleModalForImport: false,
            toggleModelForFollowUp: false,
            progress: false,
            importProgress: 0,
            error: null,
            message: null
        })
        const form = reactive({
            import_candidate: null,
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id
        })
        const candidateStore = useCandidateStore()
        candidateStore.getCandidates()
        const api_url = process.env.VUE_APP_ROOT_API

        onMounted (async () => {
            await fetchData(1)
        })
        const fetchData = async () => {
            await candidateStore.getCandidates()
        }
        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }
        const status = (candidate) => {
            return candidate.hired_candidates.length ? 'Hired' :
                candidate.shortlisted_candidates.length ? 'Shortlisted' :
                candidate.assigned_candidates.length ? 'Assigned' : 'Available';
        }
        const onPageChanged = async (page) => {
            await candidateStore.getCandidates(page);
        }

        const filterCandidate = async () => {
            await candidateStore.getCandidates(1, state.filter)
        }

        watch(state.filter, async () => {
            await candidateStore.getCandidates(1, state.filter)
        }, 300)

        const searchSkill = async (skill = null) => {
            try {
                const response = await axios.get(`${api_url}/api/skill/search/${skill}`)
                state.skills = response?.data || []
            } catch (err) {
                const response = await axios.get(`${api_url}/api/skill/`)
                state.skills = response?.data.data || []
            }
        }

        const handleImportFile = (event) => {
            form.import_candidate = event.target.files[0]
        }

        const closeModel = () => {
            state.importModel = !state.importModel
        }

        const handleImportCandidate = async () => {
            try {
                const formData = new FormData()
                Object.entries(form).forEach(([key, value]) => {
                    if (key === 'import_candidate') {
                        formData.append(key, value, value.name)
                    } else {
                        formData.append(key, value)
                    }
                })
                state.progress = true;
                const response = await axios.post(`${api_url}/api/candidate/import/`,
                    form,
                    {
                        onUploadProgress: (progressEvent) => {
                            var progress = Math.round((progressEvent.loaded * 100)  / progressEvent.total);
                            state.importProgress = progress
                            console.log(`Upload Progress: ${progress}%`);
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    if(response.status == 200)
                    {
                        state.message = response.data.message
                    }                 
            } catch (err) {
                state.error = err.response?.data?.message || 'An error occurred'
            }
        }

        return { 
            candidateStore, 
            serialNumber, 
            fetchData, 
            onPageChanged, 
            state,
            form,
            filterCandidate,
            searchSkill,
            handleImportFile,
            closeModel,
            handleImportCandidate,
            status
        }
    }
}
</script>