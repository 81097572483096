import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API

export const useSkillStore = defineStore('skillStore', {
    state: () => ({
        name: 'Skill Test',
        skill: [],
        loading: false,
        current_page: 1,
        code: null,
    }),
    actions: {
        async getSkills() {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/skill`)
                this.skill = response.data.data
                console.log(this.skill);
                this.current_page = response.data.current_page
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getSkillBySlug(id) {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/skill/${id}`)
                // console.log(response.data);
                this.skill = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Skill:', error)
            }
        },
    }
})