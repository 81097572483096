<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="skill" class="hero is-link">
                    <div class="hero-body">
                        <h1>Add</h1>
                    </div>
                </section>
                <div>
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit" class="form-container">
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input type="text" class="input" v-model="form.name" placeholder="Enter the name of the skill">
                            </div>
                        </div>
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useSkillStore } from '@/store/skillStore'
import { useAuthStore } from '@/store/auth'
export default {
    setup() {
        const skillStore = useSkillStore()
        skillStore.getSkills()

        const auth = useAuthStore()
        
        const state = reactive ({
            skill: [],
            success: ''
        })
        const form = reactive({
            name: '',
            master_client_id: auth?.master_client_id,
            client_id: auth?.client_id
        })
        const route = useRoute()
        const router = useRouter()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        const handleSubmit = async () => {
            try {
                console.log(route.params.code);
                const response = await axios.post(`${api_url}/api/skill/create`, form)
                if(response.status == 201)
                {
                    state.success = 'Data has been added successfully'
                    router.push('/skill')
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Skill:', error)
            }
        }

        return { form, handleSubmit, error, state, skillStore }
    }
}
</script>