import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { useAuthStore } from '@/store/auth'
import './assets/styles/main.scss'
import '../node_modules/bulma-extensions'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)

const authStore = useAuthStore()
authStore.fetchUser().finally(() => {
  app.use(router)
  app.mount('#app')
})