<template>
  <RouterView  v-if="!authStore.user" ></RouterView>
  <div class="main" v-if="authStore.user">
    <LeftMenu></LeftMenu>
    
    <section class="content">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="#">
            <img src="./assets/images/purpledrive.png" alt="">
          </a>

          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item">
              Home
            </a>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <!-- <a class="button is-primary">
                  <strong>Sign up</strong>
                </a> -->
                <router-link to="" class="button is-dark" @click="logout">Logout</router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <RouterView></RouterView>
    </section>
  </div>

</template>

<script>
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import LeftMenu from '@/components/common/left-menu.vue'

export default {
  components: {
    LeftMenu
  },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()

    async function logout() {
      await authStore.logout()
      router.push('/login')
    }

    return { logout, authStore }
  }
}
</script>