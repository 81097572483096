import { defineStore } from 'pinia'
import axios from 'axios'
// import { useRouter } from 'vue-router'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'

const url = process.env.VUE_APP_ROOT_API

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null,
        master_client_id: null,
        client_id: null,
        role: null,
        role_id: null
    }),
    actions: {
        async login(user) {
            await axios.get(`${url}/sanctum/csrf-cookie`)
            const res = await axios.post(`${url}/api/auth/login`, {
                email: user.email,
                password: user.password
            })
            if (res && res.data) {
                this.user = res.data.user
                this.master_client_id = res.data.master_client_id
                this.client_id = res.data.client_id
                this.role = res.data.role
                this.role_id = res?.data?.role_id
            } else {
                throw new Error('Could not complete sign in')
            }
        },
        async logout() {
            await axios.get(`${url}/api/auth/logout`)
            this.user = null
        },
        async fetchUser() {
            try {
                const res = await axios.get(`${url}/api/auth/user/`)
                // if (res && res.data) {
                //     console.log(res)
                this.user = res?.data
                this.master_client_id = res?.data?.master_client_id
                this.client_id = res?.data?.client_id
                this.role = res?.data?.role
                this.role_id = res?.data?.user.role_id
                // }
            } catch (error) {
                this.user = null
            }
        }
    },
    persist: true,
})

// Axios interceptor for handling 401 errors
export const setupAxiosInterceptors = (router) => {
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                const authStore = useAuthStore()
                authStore.logout()
                router.push('/login')
            }
            return Promise.reject(error)
        }
    )
}
