<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="role" class="hero is-link">
                    <div class="hero-body">
                        <h1>Add</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="field">
                            <div class="label">Name</div>
                            <input type="text" class="input" v-model="form.name" placeholder="Enter the name of the Role">
                        </div>
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const state = reactive ({
            role: [],
            success: ''
        })
        const form = reactive({
            name: '',
        })
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        const  handleSubmit = async () => {
            try {
                console.log(route.params.code);
                const response = await axios.post(`${api_url}/api/admin/role/create/`, form)
                if(response.status == 200)
                {
                    state.success = 'Data has been added successfully'
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Role:', error)
            }
        }

        return { form, handleSubmit, error, state }
    }
}
</script>