<template>
    <div>
        <button class="button is-dark" @click="toggleModal">View Modal</button>
        <div v-if="state.toggleModal" class="modal" v-bind:class="{ 'is-active': state.toggleModal }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="model-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Followup</p>
                        <button class="delete" aria-label="close" @click="toggleModal"></button>
                    </header>
                    <section class="modal-card-body">
                        <form @submit.prevent="handleSave">
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label" for="Analysis">Analysis</label>
                                        <div class="control">
                                            <div class="select is-fullwidth">
                                                <select v-model="form.analysis_statuses_id" name="analysis"
                                                    id="analysis">
                                                    <option value="null">Select Analysis Status</option>
                                                    <option v-for="status in state.analysis" :key="status.id"
                                                        :value="status.id">
                                                        {{ status.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label" for="Mode of contact">Mode of contact</label>
                                        <div class="control">
                                            <div class="select is-fullwidth">
                                                <select v-model="form.modeOfFollowup" name="mode" id="mode">
                                                    <option value="null">Select Mode</option>
                                                    <option v-for="mode in state.modes" :key="mode" :value="mode">{{
                                                        mode }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label" for="Comment">Next Follower</label>
                                        <div class="control">
                                            <div class="select is-fullwidth" v-if="state.recruiters">
                                                <select v-model="form.nextFollowerId" name="mode" id="mode">
                                                    <option value="null">Select Mode</option>
                                                    <option v-for="recruiters in state.recruiters" :key="recruiters.id"
                                                        :value="recruiters.id">{{ recruiters.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label" for="Comment">Next follow-up date</label>
                                        <div class="control">
                                            <input class="input" type="date" v-model="form.nextFollowupDate"
                                                name="nextFollowupDate" id="nextFollowupDate" data-display-mode="dialog"
                                                data-is-range="false" data-show-footer="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label" for="Comment">Status</label>
                                        <div class="control">
                                            <div class="select is-fullwidth" v-if="state.jobStatus">
                                                <select v-model="form.job_status_id" name="mode" id="mode">
                                                    <option value="null">Select Mode</option>
                                                    <option v-for="status in state.jobStatus" :key="status.id"
                                                        :value="status.id">{{
                                                        status.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">

                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="Comment">Comment</label>
                                <div class="control">
                                    <textarea v-model="form.comment" class="textarea"
                                        placeholder="Add your comment here"></textarea>
                                </div>
                            </div>


                            <!-- <input type="hidden" v-model="form.candidate_id" name="candidate_id">
                                <input type="hidden" v-model="form.requirement_id" name="candidate_id"> -->
                        </form>
                    </section>
                    <footer class="modal-card-foot is-justify-content-end">
                        <div class="buttons">
                            <button class="button" @click="toggleModal">Cancel</button>
                            <button class="button is-success" @click="handleSave(candidate)">Save</button>
                        </div>
                    </footer>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close"></button>

        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, reactive } from 'vue';
import axios from 'axios';
import { useUserStore } from '@/store/userStore';
import { useAnalysisStore } from '@/store/analysisStore';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
    name: 'FollowUpForm',
    props: {
        candidate: {
            type: Array,
            required: true
        },
        requirement_id: {
            type: Number,
            required: true
        },
    },
    setup(props, { emit }) {
        const userStore = useUserStore()
        const authStore = useAuthStore()
        const analysisStore = useAnalysisStore()
        const api_url = process.env.VUE_APP_ROOT_API;

        const state = reactive({
            modes: props.modes || ['Call', 'Email', 'Chat', 'VC', 'Face to Face'],
            analysis: props.analysisStatuses || [],
            error: null,
            toggleModal: false
        });

        const form = reactive({
            candidate_id: props?.candidate?.id,
            requirement_id: props?.requirement_id,
            comment: props?.candidate?.last_followup?.comment || null,
            job_status_id: props?.candidate?.last_followup?.job_status_id || null,
            modeOfFollowup: props?.candidate?.last_followup?.modeOfFollowup || null,
            nextFollowupDate: props?.candidate?.last_followup?.nextFollowupDate || null,
            analysis_statuses_id: props?.candidate?.last_followup?.analysis_statuses_id || null,
            nextFollowerId: props?.candidate?.last_followup?.nextFollowerId || null,
            followerId: authStore?.user?.user?.id,
            client_id: authStore?.client_id,
            master_client_id: authStore?.master_client_id
        });

        const handleSave = async (candidate) => {
            try {
                const jobStatusId = form.job_status_id;
                const response = await axios.post(`${api_url}/api/followup/${form.candidate_id}/create`, form);
                if(response.data)
                {
                    toggleModal();
                    emit('followup-saved', { candidate, jobStatusId });
                }
                
            } catch (error) {
                state.error = 'Error saving follow-up';
            }
        };

        const toggleModal = () => {
            state.toggleModal = !state.toggleModal
        };

        onBeforeMount(async () => {
            window.removeEventListener('keydown', handleEscapeKey);
            try {
                await userStore.getRecruiters()
                await analysisStore.getAnalysisStatus()
                await analysisStore.getJobStatus()
                await authStore.fetchUser()
                state.recruiters = await userStore.recruiters
                state.analysis = await analysisStore.analysisStatus
                state.jobStatus = await analysisStore.jobStatus
                // form.nextFollowerId = authStore.user.user.id
            } catch (err) {
                state.error.value = 'Failed to load account managers'
            }
        })

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape' && state.toggleModal) {
                toggleModal();
            }
        };

        onMounted(() => {
            window.addEventListener('keydown', handleEscapeKey);
        });

        return { state, form, handleSave, toggleModal };
    }
});
</script>