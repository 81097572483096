<template>
  <div >
    <div class="columns">
      <div class="column">
        <LoginForm />
      </div>
    </div>
  </div>
</template>
<script>

import LoginForm from '@/components/auth/LoginForm.vue'

export default {
  name: 'HomeView',
  components: {
    LoginForm
  }
}
</script>