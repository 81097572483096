<template>
     <div>
        <section class="container" v-if="candidate">
            <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">{{ candidate.name }} - {{ candidate.job_title }}</p>
                </div>
            </section>
            <div class="columns">
                <div class="column">
                    <div class="table-container">
                        <table class="table is-narrow is-hoverable is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{{ candidate.name }}</td>
                                </tr>
                                <tr>
                                    <th>Mobile</th>
                                    <td>{{ candidate.mobile }}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{{ candidate.email }}</td>
                                </tr>
                                <tr>
                                    <th>Job Title</th>
                                    <td>{{ candidate.job_title }}</td>
                                </tr>
                                <tr>
                                    <th>State</th>
                                    <td>{{ candidate.state }}</td>
                                </tr>
                                <tr>
                                    <th>Work Experience</th>
                                    <td>{{ candidate.work_experience }} Years</td>
                                </tr>
                                <tr>
                                    <th>No Of Positions</th>
                                    <td>{{ candidate.no_of_positions }}</td>
                                </tr>
                                <tr>
                                    <th>Target Date</th>
                                    <td>{{ candidate.target_date }}</td>
                                </tr>
                                <tr>
                                    <th>Qualifications</th>
                                    <td>{{ candidate.qualifications }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column"></div>
            </div>
            <hr>
        </section>
     </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

export default {
    setup () {
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const candidate = ref()

        onMounted (async () => {
            try {
                const response = await axios.get(`${api_url}/api/candidate/${route.params.code}`)
                candidate.value = response.data
            } 
            catch (err) {
                console.error('Failed to fetch candidate:', err)
            }
        })

        return { candidate }
    }
}
</script>