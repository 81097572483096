import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API

export const useAnalysisStore = defineStore('analysisStore', {
    state: () => ({
        name: 'Analysis Status',
        analysisStatus: [],
        jobStatus: [],
        loading: false
    }),
    actions: {
        async getAnalysisStatus() {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/analysis-status`);
                console.log('analysisStore');
                
                console.log(response.data);
                
                this.analysisStatus = response.data
            }
            catch (error) {
                console.error('Failed to fetch Analysis Status:', error)
            }
        },
        async getJobStatus() {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/job-status`);
                console.log('job status');
                
                console.log(response.data);
                
                this.jobStatus = response.data
            }
            catch (error) {
                console.error('Failed to fetch Analysis Status:', error)
            }
        }
    }
})