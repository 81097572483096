import CreateCandidate from '@/views/candidate/CreateCandidate.vue'
import EditCandidate from '@/views/candidate/EditCandidate.vue'
import ListCandidate from '@/views/candidate/ListCandidate.vue'
import ViewCandidate from '@/views/candidate/ViewCandidate.vue'
// import AddCandidate from '@/views/candidate/CreateCandidate.vue'
// import EditUser from '@/views/admin/user/EditUser.vue'

export default [
  {
    path: '/candidate',
    name: 'candidate',
    component: ListCandidate,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/candidate/view/:code',
    name: 'view-candidate',
    component: ViewCandidate,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/candidate/create',
    name: 'create-candidate',
    component: CreateCandidate,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/candidate/edit/:code',
    name: 'edit-candidate',
    component: EditCandidate,
    meta: {
      requiresAuth: true
    },
  },
  
]
