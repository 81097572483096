<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="user" class="hero is-link">
                    <div class="hero-body">
                        <h1>Edit</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete" @click="state.success = ''"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete" @click="error.value = ''"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="field">
                            <label class="label">Title</label>
                            <input type="text" class="input" v-model="form.title"
                                placeholder="Enter the title of the Requirement">
                        </div>
                        <div class="field">
                            <label class="label">Type</label>
                            <input type="text" class="input" v-model="form.type" placeholder="Enter the Type">
                        </div>
                        <div class="field">
                            <label class="label">Location</label>
                            <input type="text" class="input" v-model="form.location" placeholder="Enter the Location">
                        </div>
                        <div class="field">
                            <label class="label">City</label>
                            <input type="text" class="input" v-model="form.city" placeholder="Enter the City">
                        </div>
                        <div class="field">
                            <label class="label">State</label>
                            <input type="text" class="input" v-model="form.state" placeholder="Enter the State">
                        </div>
                        <div class="field">
                            <label class="label">Work Experience</label>
                            <input type="number" class="input" v-model="form.work_experience"
                                placeholder="Enter Work Experience">
                        </div>
                        <div class="field">
                            <label class="label">No Of Positions</label>
                            <input type="number" class="input" v-model="form.no_of_positions"
                                placeholder="Enter No Of Positions">
                        </div>
                        <div class="field">
                            <label class="label">Target Date</label>
                            <input type="date" class="input" v-model="form.target_date">
                        </div>
                        <div class="field">
                            <label class="label">Qualifications</label>
                            <input type="text" class="input" v-model="form.qualifications"
                                placeholder="Enter Qualifications">
                        </div>
                        <div class="field">
                            <label class="label">Account Manager</label>
                            <div class="control">
                                <div class="select">
                                    <select v-if="state.accountManagers" v-model="form.account_manager_id">
                                        <option :value="null">Select Account Manager</option>
                                        <option v-for="manager in state.accountManagers" :key="manager.id"
                                            :value="manager.id">{{ manager.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="primary skills">Primary Skills</label>
                            <div class="control">
                                <multiselect v-if="state.skills" v-model="form.primary_skills" tag-placeholder="Add this as new Skill"
                                    placeholder="Search or add a skill" label="name" track-by="id"
                                    :options="state.skills" :multiple="true" :taggable="true" :loading="true"
                                    @tag="addSkills" @search-change="searchSkill">
                                </multiselect>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label" for="secondary skills">Secondary Skills</label>
                            <div class="control">
                                <multiselect v-if="state.skills" v-model="form.secondary_skills" tag-placeholder="Add this as new Skill"
                                    placeholder="Search or add a skill" label="name" track-by="id"
                                    :options="state.skills" :multiple="true" :taggable="true" :loading="true"
                                    @tag="addSkills" @search-change="searchSkill">
                                </multiselect>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <input type="submit" class="button is-primary" value="Save" />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import { reactive, ref, onBeforeMount, watch } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/userStore'
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    setup() {
        const authStore = useAuthStore()
        const userStore = useUserStore()
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        const state = reactive({
            requirement: {},
            success: '',
            skills: [],
            accountManagers: []
        })

        const form = reactive({
            title: '',
            type: '',
            location: '',
            city: '',
            state: '',
            work_experience: '',
            no_of_positions: '',
            target_date: '',
            qualifications: '',
            account_manager_id: null,
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id,
            status: true,
            primary_skills: [],
            secondary_skills: [],
            skills: [],
        })

        onBeforeMount(async () => {
            await fetchAccountManagers();
            await fetchRequirement();
        })

        const fetchAccountManagers = async () => {
            try {
                await userStore.getAccountManagers();
                state.accountManagers = userStore.accountManagers
            } catch (error) {
                console.error('Failed to fetch Account Managers:', error);
            }
        }

        const fetchRequirement = async () => {
            try {
                const response = await axios.get(`${api_url}/api/requirement/${route.params.code}`)
                const requirement = response.data;
                Object.assign(form, {
                    title: requirement.title,
                    type: requirement.type,
                    location: requirement.location,
                    city: requirement.city,
                    state: requirement.state,
                    work_experience: requirement.work_experience,
                    no_of_positions: requirement.no_of_positions,
                    target_date: requirement.target_date,
                    qualifications: requirement.qualifications,
                    account_manager_id: requirement.account_manager_id || null,
                    primary_skills: requirement.primary_skills,
                    secondary_skills: requirement.secondary_skills
                });
            } catch (error) {
                console.error('Failed to fetch Requirement:', error);
            }
        }

        const searchSkill = async (skill = null) => {
            try {
                const response = await axios.get(`${api_url}/api/skill/search/${skill}`)
                state.skills = response?.data || []
                if (!response.data) {
                    addSkills(skill)
                }
            } catch (err) {
                const response = await axios.get(`${api_url}/api/skill/`)
                state.skills = response?.data.data || []
            }
        }

        const addSkills = async (newSkill) => {
            try {
                await axios.post(`${api_url}/api/skill/create`, {
                    name: newSkill,
                    master_client_id: authStore?.master_client_id,
                    client_id: authStore?.client_id
                })
            } catch (err) {
                error.value = err.response?.data?.message || 'Failed to add skill'
            }
        }

        watch(() => {
            const primarySkills = form.primary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 1
            }))
            const secondarySkills = form.secondary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 0
            }))

            form.skills = [...primarySkills, ...secondarySkills]

        }, { deep: true })

        const handleSubmit = async () => {
            try {
                const response = await axios.put(`${api_url}/api/requirement/update/${route.params.code}`, form)
                if (response.status === 200) {
                    state.success = 'Data has been updated successfully'
                }
            } catch (err) {
                error.value = err.response?.data?.message || 'An error occurred'
            }
        }

        return { form, handleSubmit, error, state, searchSkill, addSkills }
    }
}
</script>
