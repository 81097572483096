<template>
  <nav class="pagination" role="navigation" aria-label="pagination">
    <a
      class="pagination-previous"
      :class="{ 'is-disabled': !previousAvailable }"
      @click="goToPage('previous')"
    >
      Previous
    </a>
    <a
      class="pagination-next"
      :class="{ 'is-disabled': !nextAvailable }"
      @click="goToPage('next')"
    >
      Next page
    </a>
    <ul class="pagination-list">
      <li v-for="link in sanitizedLinks" :key="link.label">
        <a
          v-if="link.url"
          :class="['pagination-link', { 'is-current': link.active }]"
          @click="goToPage(link.label)"
        >
          {{ sanitizeLabel(link.label) }}
        </a>
        <span v-else class="pagination-ellipsis">&hellip;</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'UserPagination',
  props: {
    links: {
      type: Array,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
    last_page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    previousAvailable() {
      return this.current_page > 1;
    },
    nextAvailable() {
      return this.current_page < this.last_page;
    },
    sanitizedLinks() {
      return this.links.filter(link => !link.label.includes('Previous') && !link.label.includes('Next'));
    },
  },
  methods: {
    sanitizeLabel(label) {
      const entityMap = {
        '&laquo;': '«',
        '&raquo;': '»',
      };
      return label.replace(/&laquo;|&raquo;/g, match => entityMap[match]);
    },
    goToPage(label) {
      let page;
      if (label === 'previous') {
        page = this.current_page - 1;
      } else if (label === 'next') {
        page = this.current_page + 1;
      } else {
        page = parseInt(label);
      }
      if (page !== this.current_page && page > 0 && page <= this.last_page) {
        this.$emit('page-changed', page);
      }
    },
  },
};
</script>