<template>
    <div>
        <LeftMenu></LeftMenu>
        <section class="container">
            <section class="container">
                <section v-if="masterClient" class="hero is-link">
                    <div class="hero-body">
                        <h1>Edit</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="field">
                            <div class="label">Name</div>
                            <input type="text" class="input" v-model="form.name" placeholder="Enter the name of the client">
                        </div>
                        <div class="field">
                            <div class="label">Mobile</div>
                            <input type="text" class="input" v-model="form.mobile" placeholder="Enter mobile number">
                        </div>
                        <div class="field">
                            <div class="label">Email</div>
                            <input type="text" class="input" v-model="form.email" placeholder="Enter email">
                        </div>
                        <div class="field">
                            <div class="label">Contact Person</div>
                            <input type="text" class="input" v-model="form.contactPerson" placeholder="Enter contact person name">
                        </div>
                        <div class="field">
                            <div class="label">Address Line 1</div>
                            <input type="text" class="input" v-model="form.addressLine1" placeholder="Enter the address">
                        </div>
                        <div class="field">
                            <div class="label">Address Line 2</div>
                            <input type="text" class="input" v-model="form.addressLine2" placeholder="Enter the address">
                        </div>
                        <div class="field">
                            <div class="label">City</div>
                            <input type="text" class="input" v-model="form.city" placeholder="Enter the city">
                        </div>
                        <div class="field">
                            <div class="label">State</div>
                            <input type="text" class="input" v-model="form.state" placeholder="Enter the state">
                        </div>
                        <div class="field">
                            <div class="label">Pin</div>
                            <input type="text" class="input" v-model="form.pin" placeholder="Enter the pin">
                        </div>
                        <div class="field">
                            <div class="label">Gst</div>
                            <input type="text" class="input" v-model="form.gst" placeholder="Enter the gst">
                        </div>
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const state = reactive ({
            masterClient: [],
            success: ''
        })
        const form = reactive({
            name: '',
            mobile: '',
            email: '',
            contactPerson: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pin: '',
            gst: ''
        })
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        // const masterClient = ref([])
        const error = ref()
        // const success = ref()
        onMounted(async () => {
            try {
                const response = await axios.get(`${api_url}/api/admin/master-client/${route.params.code}`)
                state.masterClient = response.data
                form.name = state.masterClient.name
                form.mobile = state.masterClient.mobile
                form.email = state.masterClient.email
                form.contactPerson = state.masterClient.contactPerson
                form.addressLine1 = state.masterClient.addressLine1
                form.addressLine2 = state.masterClient.addressLine2
                form.city = state.masterClient.city
                form.state = state.masterClient.state
                form.pin = state.masterClient.pin
                form.gst = state.masterClient.gst
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        })

        const  handleSubmit = async () => {
            try {
                console.log(route.params.code);
                const response = await axios.put(`${api_url}/api/admin/master-client/update/${route.params.code}`, form)
                if(response.status == 200)
                {
                    state.success = 'Data has been updated successfully'
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Master Client:', error)
            }
        }

        return { form, handleSubmit, error, state }
    }
}
</script>