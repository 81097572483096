import ListUser from '@/views/admin/user/ListUser.vue'
import CreateUser from '@/views/admin/user/CreateUser.vue'
import ViewUser from '@/views/admin/user/ViewUser.vue'
import EditUser from '@/views/admin/user/EditUser.vue'

export default [
  {
    path: '/user',
    name: 'user',
    component: ListUser,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user/create',
    name: 'create-user',
    component: CreateUser,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user/view/:code',
    name: 'view-user',
    component: ViewUser,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user/edit/:code',
    name: 'edit-user',
    component: EditUser,
    meta: {
      requiresAuth: true
    },
  }
]
