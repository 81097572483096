import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import DashboardView from '@/views/admin/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'
import { useAuthStore } from '@/store/auth'
import masterClientRoutes from './masterClientRoutes'
import clientRoutes from './clientRoutes'
import userRoutes from './userRoutes'
import roleRoutes from './roleRoutes'
import skillRouter from './skillRouter'
import requirementRoutes from './requirementRoutes'
import candidateRoutes from './candidateRoutes'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/:404',
    name: '404',
    component: NotFound
  },
  ...masterClientRoutes,
  ...clientRoutes,
  ...userRoutes,
  ...roleRoutes,
  ...skillRouter,
  ...requirementRoutes,
  ...candidateRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (to.meta.requiresAuth && !authStore.user) {
    next('/login')
  } else {
    next()
  }
})

export default router
