<template>
    <div>
        <!-- <LeftMenu></LeftMenu> -->
        <section class="container">
            <section class="container">
                <section v-if="client" class="hero is-link">
                    <div class="hero-body">
                        <p class="title">{{ client.name }}</p>
                    </div>
                </section>
                <div class="table-container">
                    <table class="table is-bordered is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{{ client.name }}</td>
                            </tr>
                            <tr>
                                <th>Master</th>
                                <td>{{ client.master?.name }}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{{ client.mobile }}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{ client.email }}</td>
                            </tr>
                            <tr>
                                <th>Contact Person</th>
                                <td>{{ client.contactPerson }}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{{ client.addressLine1 }}, {{ client.addressLine2 }}</td>
                            </tr>
                            <tr>
                                <th>City</th>
                                <td>{{ client.city }}</td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>{{ client.state }}</td>
                            </tr>
                            <tr>
                                <th>Pin</th>
                                <td>{{ client.pin }}</td>
                            </tr>
                            <tr>
                                <th>GST</th>
                                <td>{{ client.gst }}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td v-if="client.status">Active</td>
                                <td v-else>In Active</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import axios from 'axios'
// import { useclientStore } from '../../../store/clientStore'
import { useRoute } from 'vue-router'
// import LeftMenu from '../../../components/common/left-menu.vue'
export default {
    // components: {
    //     LeftMenu
    // },
    setup() {
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const client = ref([])
        onMounted(async () => {
            try {
                const response = await axios.get(`${api_url}/api/master-client/client/${route.params.code}`)
                client.value = response.data
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        })

        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }

        return { client }
    }
}
</script>