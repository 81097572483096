<template>
    <div>
        <!-- <LeftMenu></LeftMenu> -->
        <section class="container">
            <section class="container">
                <section v-if="user" class="hero is-link">
                    <div class="hero-body">
                        <h1>Edit</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete" @click="state.success = ''"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete" @click="error.value = ''"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="columns">
                            <div class="column">
                                <!-- <div class="field">
                                    <label class="label">Name</label>
                                    <input type="text" class="input" v-model="form.name"
                                        placeholder="Enter the name of the Candidate">
                                </div> -->
                                <div class="field">
                                    <label class="label">First Name</label>
                                    <input type="text" class="input" v-model="form.first_name" placeholder="Enter the First Name">
                                </div>
                                <div class="field">
                                    <label class="label">Middle Name</label>
                                    <input type="text" class="input" v-model="form.middle_name" placeholder="Enter the Middle Name">
                                </div>
                                <div class="field">
                                    <label class="label">Last Name</label>
                                    <input type="text" class="input" v-model="form.last_name" placeholder="Enter the Last Name">
                                </div>
                                <div class="field">
                                    <label class="label">Mobile</label>
                                    <input type="text" class="input" v-model="form.mobile" placeholder="Enter the Mobile Number">
                                </div>
                                <div class="field">
                                    <label class="label">Email</label>
                                    <input type="email" class="input" v-model="form.email" placeholder="Enter the email">
                                </div>
                                <div class="field">
                                    <label class="label">Date of birth</label>
                                    <input type="date" class="input" v-model="form.date_of_birth" placeholder="Enter the email">
                                </div>
                                <div class="field">
                                    <label class="label">Job Title</label>
                                    <input type="text" class="input" v-model="form.job_title" placeholder="Enter the job title">
                                </div>
                                <div class="field">
                                    <label class="label">Total Experience</label>
                                    <input type="number" class="input" v-model="form.total_experience"
                                        placeholder="Enter Total Experience">
                                </div>
                                <div class="field">
                                    <label class="label">Relevant Experience</label>
                                    <input type="number" class="input" v-model="form.relevant_experience"
                                        placeholder="Enter relevant experience">
                                </div>
                                <div class="field">
                                    <label class="label">Current Company</label>
                                    <input type="text" class="input" v-model="form.current_company" placeholder="Enter the current company">
                                </div>
                                <div class="field">
                                    <label class="label">Current Work Location</label>
                                    <input type="text" class="input" v-model="form.current_work_location" placeholder="Enter the current work location">
                                </div>
                                <div class="field">
                                    <label class="label">Preferred Work Location</label>
                                    <input type="text" class="input" v-model="form.preferred_work_location" placeholder="Enter the preferred work location">
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Notice Period</label>
                                    <input type="text" class="input" v-model="form.notice_period" placeholder="Enter the notice period">
                                </div>
                                <div class="field">
                                    <label class="label">Availability</label>
                                    <input type="text" class="input" v-model="form.availability" placeholder="Enter the availability">
                                </div>
                                <div class="field">
                                    <label class="label">Current CTC</label>
                                    <input type="number" class="input" v-model="form.current_ctc" placeholder="Enter the Current CTC">
                                </div>
                                <div class="field">
                                    <label class="label">Expected CTC</label>
                                    <input type="number" class="input" v-model="form.expected_ctc" placeholder="Enter the Expected CTC">
                                </div>
                                <div class="field">
                                    <label class="label">Address</label>
                                    <input type="text" class="input" v-model="form.address" placeholder="Enter the address">
                                </div>
                                <div class="field">
                                    <label class="label">City</label>
                                    <input type="text" class="input" v-model="form.city" placeholder="Enter the city">
                                </div>
                                <div class="field">
                                    <label class="label">State</label>
                                    <input type="text" class="input" v-model="form.state" placeholder="Enter the State">
                                </div>
                                <div class="field">
                                    <label class="label">Country</label>
                                    <input type="text" class="input" v-model="form.country" placeholder="Enter the country">
                                </div>
                                <div class="field">
                                    <label class="label">Pin</label>
                                    <input type="text" class="input" v-model="form.pin" placeholder="Enter the pin">
                                </div>
                                <div class="field">
                                    <label class="label">Qualifications</label>
                                    <input type="text" class="input" v-model="form.qualifications"
                                        placeholder="Enter Qualifications">
                                </div>
                                <div class="field">
                                    <label class="label" for="primary skills">Primary Skills</label>
                                    <div class="control">
                                        <multiselect v-if="state.skills" v-model="form.primary_skills" tag-placeholder="Add this as new Skill"
                                            placeholder="Search or add a skill" label="name" track-by="id" :options="state.skills"
                                            :multiple="true" :taggable="true" :loading="true" @tag="addSkills"
                                            @search-change="searchSkill">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label" for="secondary skills">Secondary Skills</label>
                                    <div class="control">
                                        <multiselect v-if="state.skills" v-model="form.secondary_skills" tag-placeholder="Add this as new Skill"
                                            placeholder="Search or add a skill" label="name" track-by="id" :options="state.skills"
                                            :multiple="true" :taggable="true" :loading="true" @tag="addSkills"
                                            @search-change="searchSkill">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Resume</label>
                                    <div class="file has-name">
                                        <label class="file-label">
                                            <input class="file-input" type="file" @change="handleFileUpload"
                                                accept=".pdf,.doc,.docx" />
                                            <span class="file-cta">
                                                <span class="file-icon">
                                                    <i class="fas fa-upload"></i>
                                                </span>
                                                <span class="file-label">Choose a file…</span>
                                            </span>
                                            <span class="file-name">{{ form.resume ? form.resume.name : state.existingResumeName
                                                }}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Photo</label>
                                    <div class="file has-name">
                                        <label class="file-label">
                                            <input class="file-input" type="file" @change="handleAvatarUpload"
                                                accept="image/*" />
                                            <span class="file-cta">
                                                <span class="file-icon">
                                                    <i class="fas fa-upload"></i>
                                                </span>
                                                <span class="file-label">Choose an image…</span>
                                            </span>
                                            <span class="file-name">{{ form.candidate_image 
                                                                    ? form.candidate_image.name 
                                                                    : state.existingImageName
                                                }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="field">
                            <div class="control">
                                <input type="submit" class="button is-primary" value="Save" />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/userStore'
// import LeftMenu from '@/components/common/left-menu.vue'
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    setup() {
        const authStore = useAuthStore()
        const userStore = useUserStore()
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        const state = reactive({
            candidate: {},
            success: '',
            skills: [],
            accountManagers: [],
            imagePreview: null,
            existingResumeName: null,
            existingImageName: null,
        })

        const form = reactive({
            // name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            mobile: '',
            email: '',
            job_title: '',
            total_experience: '',
            relevant_experience: '',
            current_company: null,
            current_work_location: '',
            preferred_work_location: '',
            notice_period: '',
            availability: '',
            current_ctc: '',
            expected_ctc: '',
            address: '',
            city: '',
            state: '',
            country: '',
            pin: '',
            date_of_birth: '',
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id,
            status: true,
            primary_skills: [],
            secondary_skills: [],
            skills: [],
            resume: null,
            candidate_image: null,
        })

        onMounted(async () => {
            await fetchAccountManagers();
            await fetchCandidate();
        })

        const fetchAccountManagers = async () => {
            try {
                await userStore.getAccountManagers();
                state.accountManagers = userStore.accountManagers
            } catch (error) {
                console.error('Failed to fetch Account Managers:', error);
            }
        }

        const fetchCandidate = async () => {
            try {
                const response = await axios.get(`${api_url}/api/candidate/${route.params.code}`)
                // const candidate = response.data;
                state.candidate = response.data
                Object.assign(form, state.candidate)
                
            } catch (error) {
                console.error('Failed to fetch Candidate:', error);
            }
        }

        const searchSkill = async (skill = null) => {
            try {
                const response = await axios.get(`${api_url}/api/skill/search/${skill}`)
                state.skills = response?.data || []
                if (!response.data) {
                    addSkills(skill)
                }
            } catch (err) {
                const response = await axios.get(`${api_url}/api/skill/`)
                state.skills = response?.data.data || []
            }
        }

        const addSkills = async (newSkill) => {
            try {
                await axios.post(`${api_url}/api/skill/create`, {
                    name: newSkill,
                    master_client_id: authStore?.master_client_id,
                    client_id: authStore?.client_id
                })
            } catch (err) {
                error.value = err.response?.data?.message || 'Failed to add skill'
            }
        }

        watch(() => {
            const primarySkills = Array.isArray(form.primary_skills)
                ? form.primary_skills.map(skill => ({
                    skill_id: skill.id,
                    is_primary: 1
                }))
                : [];

            const secondarySkills = Array.isArray(form.secondary_skills)
                ? form.secondary_skills.map(skill => ({
                    skill_id: skill.id,
                    is_primary: 0
                }))
                : [];

            form.skills = [...primarySkills, ...secondarySkills];
            state.existingResumeName = form.resume_path ?
                                        form.resume_path.split('/').pop()
                                        : 'No file chosen'
            state.existingImageName =  form.avatar ?
                                        form.avatar.split('/').pop()
                                        : 'No file chosen'
        }, { deep: true });

        const handleFileUpload = (event) => {
            const file = event.target.files[0]
            if (file) {
                form.resume = file
            }
        }

        const handleAvatarUpload = (event) => {
            const file = event.target.files[0]
            if (file) {
                form.candidate_image = file
            }
        }

        const handleSubmit = async () => {
            try {

                const formData = new FormData()
                Object.entries(form).forEach(([key, value]) => {
                    if (key === 'resume' && value !== null) {
                        formData.append(key, value, value.name)
                    } else if (key === 'candidate_image' && value !== null) {
                        formData.append(key, value, value.name)
                    } else {
                        formData.append(key, value)
                    }
                })

                const response = await axios.post(
                    `${api_url}/api/candidate/update/${route.params.code}`,
                     form,
                     {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                if (response.status === 200) {
                    state.success = 'Data has been updated successfully'
                }
            } catch (err) {
                error.value = err.response?.data?.message || 'An error occurred'
            }
        }

        return { 
            form, 
            handleSubmit, 
            error, 
            state, 
            searchSkill, 
            addSkills, 
            handleFileUpload, 
            handleAvatarUpload }
    }
}
</script>
