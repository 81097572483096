<template>
    <div>
        <section class="container">
            <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">Master Client List</p>
                </div>
            </section>
            <hr>
            <!-- {{ maserClients.data }} -->
              <div v-if="masterClientStore.loading">
                <p>Data is loading....</p>
              </div>
            <section class="container">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <p v-if="masterClientStore.total">{{ masterClientStore.total }} Active master clients available</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <p class="level-item"><router-link :to="'/master-client/add/'" class="button is-primary">Add</router-link></p>
                    </div>
                </nav>
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="masterClientStore.masterClient">
                        <tr v-for="(maserClient, index) in masterClientStore.masterClient" :key="maserClient.id">
                            <td>{{ serialNumber(index, masterClientStore.current_page) }}</td>
                            <td>{{ maserClient.name }}</td>
                            <td>{{ maserClient.email }}</td>
                            <td>{{ maserClient.mobile }}</td>
                            <td>
                                <div class="buttons">
                                    <router-link :to="'/master-client/view/' + maserClient.code" class="button is-info">View</router-link>
                                    <!-- <button class="button is-info">View</button> -->
                                    <!-- <button class="button is-warning">Edit</button> -->
                                    <router-link :to="'/master-client/edit/' + maserClient.code" class="button is-warning">Edit</router-link>
                                    <button class="button is-danger">Delete</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </section>
        </section>        
    </div>
</template>
<script>

import { useMasterClientStore } from '@/store/masterClientStore'
export default {
    setup() {
        const masterClientStore = useMasterClientStore()
        masterClientStore.getMasterClients()
        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }
        return { masterClientStore, serialNumber }
    }
}
</script>