import ListViewMasterClient from '@/views/admin/masterClient/ListViewMasterClient.vue'
import ViewMasterClient from '@/views/admin/masterClient/ViewMasterClient.vue'
import AddMasterClient from '@/views/admin/masterClient/AddMasterClient.vue'
import EditMasterClient from '@/views/admin/masterClient/EditMasterClient.vue'

export default [
  {
    path: '/master-client',
    name: 'master-client',
    component: ListViewMasterClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/master-client/view/:code',
    name: 'view-master-client',
    component: ViewMasterClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/master-client/add',
    name: 'add-master-client',
    component: AddMasterClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/master-client/edit/:code',
    name: 'edit-master-client',
    component: EditMasterClient,
    meta: {
      requiresAuth: true
    },
  }
]
