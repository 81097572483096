<template>
  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <section class="hero is-light">
    <div class="hero-body">
      <p class="title">ProAct Lead Management</p>
      <p class="subtitle">Manage your jobs and candidates follow-up</p>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  // name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script>
