<template>
    <div class="box">
        <h2 class="title is-4 mb-2">{{ title }}</h2>
        <div class="list has-visible-pointer-controls" v-if="candidates.length">
            <div class="list-item" v-for="candidate in candidates" :key="candidate.id">
                <div class="list-item-image">
                    <figure class="image is-48x48">
                        <img class="is-rounded" :src="getAvatarPath(candidate.avatar)" alt="">
                    </figure>
                </div>
                <div class="list-item-content">
                    <div class="list-item-title">{{ candidate.name }}</div>
                    <div class="list-item-description">
                        <div class="tag is-rounded">{{ candidate.email }}</div>
                        <div class="tag is-rounded">{{ candidate.mobile }}</div>
                    </div>
                </div>
                <div class="list-item-controls" v-if="status">
                    <div class="buttons is-right">
                        <!-- <ModelContainer title="Follow-up Candidate" @save="handleFollowUpSave(candidate)"> -->
                            <!-- {{ candidate }} -->
                            <FollowUpForm
                                :candidate="candidate"
                                :requirement_id="requirement_id"
                                 @followup-saved="handleFollowUpSave"
                            ></FollowUpForm>
                        <!-- </ModelContainer> -->
                        <!-- <button v-if="status === 'assigned'" class="button" @click="assignCandidate(candidate)">Assign</button> -->
                        <!-- <button class="button" @click="assignCandidate(candidate, statusId)">{{ status }}</button> -->
                    </div>
                </div>
            </div>
        </div>
        <ListPagination v-if="pagination?.last_page > 1" :links="pagination.links"
            :current_page="pagination.current_page" :last_page="pagination.last_page"
            @page-changed="onPageChanged"
             />
    </div>
</template>
<script>
import ListPagination from '@/components/common/listPagination.vue';
import { defineComponent, toRefs } from 'vue';
// import ModelContainer from '@/components/common/model.vue';
import { reactive } from 'vue';

// import axios from 'axios'
import FollowUpForm from '@/components/Candidate/FollowUpForm.vue';
export default defineComponent({
    name: 'ListCandidate',
    props: {
        title: {
            type: String,
            required: true
        },
        candidates: {
            type: Array,
            required: true
        },
        requirement_id: {
            type: Number,
            required: true
        },
        pagination: {
            type: Array,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        statusId: {
            type: Number,
            required: true
        }
    },
    components: {
        ListPagination, FollowUpForm
    },
    setup(props, { emit }) {
        
        const api_url = process.env.VUE_APP_ROOT_API;

        const state = reactive({
            toggleModalForFollowUp: false,
            message: null,
            error: null,
            analysis: [],
            jobStatus: [],
            modes: ['Call', 'Email', 'Chat', 'VC', 'Face to Face'],
            recruiters: []
        })

        const form = reactive({
            candidate_id: null,
            requirement_id: null,
            comment: null,
            job_status_id: null,
            modeOfFollowup: null,
            nextFollowupDate: null,
            analysis_statuses_id: null,
            nextFollowerId: null,
            followerId: null,
            client_id: null,
            master_client_id: null
        })

        const getAvatarPath = (path) => {
            return api_url + '/' + path;
        };

        const onPageChanged = (page) => {
            console.log(page);
            
            emit('page-changed', page);
        };

        const assignCandidate = (candidate, jobStatusId, statusId) => {            
            emit('assign-candidate', candidate, jobStatusId, statusId);
        };

        const handleFollowUpSave = async ({candidate, jobStatusId}) => {

            console.log(candidate);
            console.log(jobStatusId);
            
            console.log('followup-saved');
            emit('assign-candidate', candidate, jobStatusId, props.statusId);
            
            // form.candidate_id = candidate.id
            // const response = await axios.post(`${api_url}/api/followup/${candidate.id}/create`, form);
            // console.log(candidate);
            
            // console.log(response);            
        }

        

        
        return { 
            getAvatarPath, 
            form,
            state,
            assignCandidate, 
            handleFollowUpSave,
            onPageChanged, 
            ...toRefs(props) 
        }
    }
})
</script>