import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API

export const useMasterClientStore = defineStore('masterClientStore', {
    state: () => ({
        name: 'Master Client Test',
        masterClient: [],
        loading: false,
        current_page: 1,
        code: null,
        total: null
    }),
    actions: {
        async getMasterClients() {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/admin/master-client/`)
                console.log(response.data);
                this.masterClient = response.data.data
                this.current_page = response.data.current_page
                this.total = response.data.total
                this.loading = false                
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getMasterClientBySlug(slug) {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/admin/master-client/${slug}`)
                // console.log(response.data);
                this.masterClient = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        // async addMasterClient(masterClient) {
        //     try {
        //         const response = await axios.post(`${api_url}/api/master-client/client/create`, masterClient)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async updateMasterClient(masterClient) {
        //     try {
        //         const response = await axios.put(`${api_url}/api/master-client/client/update/${masterClient.slug}`, masterClient)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async deleteMasterClient(masterClientId) {
        //     try {

        //     }
        // }
    }
})