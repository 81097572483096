<template>
    <div class="intro">
        <section class="section container">
            <div class="columns">
                <div class="column logo">
                    <h1 class="is-size-1">Job Management System</h1>
                    <hr>
                    <p>Manage all your jobs and candidates</p>
                </div>
                <div class="column login-form is-align-content-center">
                    <div class="container">
                        <figure class="has-text-centered    ">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </figure>
                        <div v-if="error" class="notification is-danger">
                            <button class="delete"></button>
                            {{ error }}
                        </div>
                        <form @submit.prevent="loginUser">
                            <div class="field">
                                <label class="label">Email</label>
                                <div class="control">
                                    <input v-model="form.email" class="input" type="email" placeholder="Enter your email">
                                </div>
                            </div>
                            <br>
                            <div class="field">
                                <label class="label">Password</label>
                                <div class="control">
                                    <input v-model="form.password" class="input" type="password" placeholder="Enter your password">
                                </div>
                            </div>
                            <br>
                            <div class="field">
                                <div class="control">
                                    <button class="button is-primary">Login</button>
                                </div>                
                            </div>            
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'
export default {
    setup() {
        const form = ref({ email: null, password: null })
        const authStore = useAuthStore()
        const router = useRouter()
        const error = ref(null)
        async function loginUser() {
            try {
                await authStore.login({
                    email: form.value.email,
                    password: form.value.password
                })
                router.push('/dashboard')
            } catch (err) {
                error.value = err.response?.data?.message || 'An error occurred'
            }
        }
        return {
            form, loginUser, error
        }
    },
}
</script>