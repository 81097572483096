<template>
    <div class="box">
        <h2 class="title is-4 mb-2">{{ title }}</h2>
        <div class="list has-visible-pointer-controls" v-if="jobs.length">
            <div class="list-item" v-for="job in jobs" :key="job.id">
                <!-- <div class="list-item-image">
                    <figure class="image is-48x48">
                        <img class="is-rounded" :src="getAvatarPath(job.avatar)" alt="">
                    </figure>
                </div> -->
                <div class="list-item-content">
                    <div class="list-item-title">{{ job.title }}</div>
                    <div class="list-item-description">
                        <!-- <div class="tag is-rounded">{{ job.email }}</div>
                        <div class="tag is-rounded">{{ job.mobile }}</div> -->
                    </div>
                </div>
                <div class="list-item-controls" v-if="status">
                    <div class="buttons is-right">
                        <!-- <button v-if="status === 'assigned'" class="button" @click="assignCandidate(candidate)">Assign</button> -->
                        <button class="button" @click="assignJob(job, statusId)">{{ status }}</button>
                    </div>
                </div>
            </div>
        </div>
        <ListPagination v-if="pagination?.last_page > 1" :links="pagination.links"
            :current_page="pagination.current_page" :last_page="pagination.last_page"
            @page-changed="onPageChanged"
             />
    </div>
</template>
<script>
// import { isInteger } from 'core-js/core/number';

// import { ref, onMounted, reactive, computed } from 'vue';
// import axios from 'axios';
import ListPagination from '@/components/common/listPagination.vue';
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
    name: 'ListJob',
    props: {
        title: {
            type: String,
            required: true
        },
        jobs: {
            type: Array,
            required: true
        },
        pagination: {
            type: Array,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        statusId: {
            type: Number,
            required: true
        }
    },
    components: {
        ListPagination
    },
    setup(props, { emit }) {

        // const api_url = process.env.VUE_APP_ROOT_API;

        // const getAvatarPath = (path) => {
        //     return api_url + '/' + path;
        // };

        const onPageChanged = (page) => {
            console.log(page);
            
            emit('page-changed', page);
        };

        const assignJob = (job, statusId) => {            
            emit('assign-jon', job, statusId);
        };

        return { assignJob, onPageChanged, ...toRefs(props) }
    }
})
</script>