<template>
    <div>
        <section class="container">
            <!-- <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">Requirement</p>
                </div>
            </section> -->
            
            <!-- {{ maserClients.data }} -->
              <div v-if="requirementStore.loading">
                <p>Data is loading....</p>
              </div>
            <section class="container">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <p>Requirement lists</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <button class="level-item"><router-link :to="'/requirement/create/'" class="button is-primary">Add</router-link></button>
                        <button class="button is-link" 
                            @click="state.toggleModalForImport = !state.toggleModalForImport">import</button>
                    </div>
                </nav>
                <hr>
                <div class="modal" v-bind:class="{'is-active': state.toggleModalForImport}">
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                        <p class="modal-card-title">Import Candidates</p>
                        <button class="delete" aria-label="close" @click="state.toggleModalForImport = !state.toggleModalForImport"></button>
                        </header>
                        <section class="modal-card-body">
                            <div class="notification is-success" v-if="state.message">
                                <button class="delete" @click="state.message = !state.message"></button>
                                {{ state.message }}
                            </div>
                            <div class="notification is-danger" v-if="state.error">
                                <button class="delete" @click="state.error = !state.error"></button>
                                {{ state.error }}
                            </div>
                            <div class="file is-boxed">
                                <label class="file-label">
                                    <input class="file-input" type="file" @change="handleImportFile" />
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label"> Choose a file… </span>
                                    </span>
                                    <span class="file-name">{{ form.import_requirement ? form.import_requirement.name : 'No file chosen'
                                        }}</span>
                                </label>
                            </div>
                            <progress v-show="state.progress" class="progress is-success" 
                                :value="state.importProgress" max="100">
                                {{ state.importProgress }}
                            </progress>
                        </section>
                        <footer class="modal-card-foot is-justify-content-end">
                        <div class="buttons">
                            
                            <button class="button" @click="state.toggleModalForImport = !state.toggleModalForImport">Cancel</button>
                            <button class="button is-success" @click="handleImportRequirement">Import</button>
                        </div>
                        </footer>
                    </div>
                </div>
                <div class="columns is-multiline" v-if="requirementStore.requirement">
                    <div class="column is-4" v-for="requirement in requirementStore.requirement" :key="requirement.id">
                        <div class="card">
                            <div class="card-content">
                                <span class="is-6">Role</span>
                                <p class="title is-4">{{ requirement?.title }}</p>
                                <span class="is-6">{{ requirement.client.name }}</span>
                                <p>Candidates</p>
                                <div class="fixed-grid">
                                    <div class="grid">
                                        <div class="cell">
                                            <span class="is-2"><b>In Progress</b></span>
                                            <br>
                                            <span class="is-size-1">{{ requirement.candidates_count }}</span>
                                        </div>
                                        <div class="cell">
                                            <span class="is-2"><b>Hired</b></span>
                                            <br>
                                            <span class="is-size-1">{{ requirement.hired_candidates_count }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="level">
                                    <div class="level-left">
                                        <span>{{ requirement.type }}</span>
                                    </div>
                                    <div class="level-right">
                                        <span>{{ requirement.account_manager_details?.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <footer class="card-footer">
                                <span class="card-footer-item is-capitalized">Status: {{ getStatus(requirement?.status) }}</span>
                                <router-link :to="'/requirement/view/' + requirement.code" class="card-footer-item button is-info">View</router-link>
                                    <router-link :to="'/requirement/edit/' + requirement.code" class="card-footer-item button is-warning">Edit</router-link>
                            </footer>
                        </div>
                    </div>
                </div>
                <!-- <div class="table">
                    <thead></thead>
                    <tbody>
                        <tr v-if="requirementStore.requirement">
                            <td></td>
                        </tr>
                    </tbody>
                </div> -->
                <ListPagination
                    v-if="requirementStore?.pagination && requirementStore?.pagination?.last_page > 1"
                    :links="requirementStore.pagination.links"
                    :current_page="requirementStore.pagination.current_page"
                    :last_page="requirementStore.pagination.last_page"
                    @page-changed="fetchData"
                />
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, onMounted } from 'vue';
import { useRequirementStore } from '@/store/requirementStore'
import ListPagination from '@/components/common/listPagination.vue';
import axios from 'axios'
import { useAuthStore } from '@/store/auth';

export default {
    components: {
        ListPagination
    },
    setup() {
        const authStore = useAuthStore()

        const state = reactive({
            statuses: [],
            toggleModalForImport: false,
            progress: false,
            importProgress: 0,
            message: null,
            error: null,
        })
        const form = reactive({
            import_requirement: null,
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id
        })
        const api_url = process.env.VUE_APP_ROOT_API

        const requirementStore = useRequirementStore()
        
        
        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }
        onMounted (async () => {
            await requirementStore.getRequirements()
            await requirementStore.getStatuses()
            state.statuses = requirementStore.statuses
        })

        const fetchData = async (page) => {
            await requirementStore.getRequirements(page)
        }

        const getStatus = (id) => {
            const status = state.statuses.find(status => status.id === id);
            return status ? status.name : 'Status not found';
        }

        const handleImportFile = (event) => {
            form.import_requirement = event.target.files[0]
        }

        const handleImportRequirement = async () => {
            try {
                const formData = new FormData()
                Object.entries(form).forEach(([key, value]) => {
                    if (key === 'import_requirement') {
                        formData.append(key, value, value.name)
                    } else {
                        formData.append(key, value)
                    }
                })
                state.progress = true;
                const response = await axios.post(`${api_url}/api/requirement/import/`,
                    form,
                    {
                        onUploadProgress: (progressEvent) => {
                            var progress = Math.round((progressEvent.loaded * 100)  / progressEvent.total);
                            state.importProgress = progress
                            console.log(`Upload Progress: ${progress}%`);
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    if(response.status == 200)
                    {
                        state.message = response?.data?.message
                    }                 
            } catch (err) {
                state.error = err.response?.data?.message || 'An error occurred'
            }
        }

        return { 
            requirementStore, 
            state, 
            form,
            serialNumber, 
            fetchData, 
            getStatus,
            handleImportFile,
            handleImportRequirement
        }
    }
}
</script>