import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API

export const useRequirementCandidateStore = defineStore('requirementCandidateStore', {
    state: () => ({
        name: "Suggested Candidates",
        candidates: [],
        pagination: null,
        loading: false,
        current_page: 1,
        status: 1,
        statusState: 'suggested'
    }),
    actions: {
        async fetchSuggestedCandidates (id, page = 1) {
            try {
                this.loading = true
                const response = await axios.get(
                    `${api_url}/api/requirement/suggested-candidates/${id}`, 
                        {
                            params: {
                                page: page
                            }
                     });
                this.candidates = response.data.data
                this.current_page = response.data.current_page
                this.pagination = {
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    links: response.data.links.map(link => ({
                      url: link.url,
                      label: link.label,
                      active: link.active,
                    })),
                };
                this.loading = false
            } catch (error) {
                console.error('Failed to fetch Suggested Candidates:', error)
            }
        },
        async fetchCandidates(id, filterData, page = 1)
        {
            try {
                
                const response = await axios.get(`${api_url}/api/requirement/${id}/candidates`, {
                    params: {
                        page: page,
                        filter: filterData
                    }
                });
                console.log(response);
                
                this.candidates = response.data.data
                this.current_page = response.data.current_page
                this.pagination = {
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    links: response.data.links.map(link => ({
                      url: link.url,
                      label: link.label,
                      active: link.active,
                    })),
                };
                this.loading = false
            } catch (error) {
                console.error('Failed to fetch Assigned Candidates:', error)
            }
        }
    }
})