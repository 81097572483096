import ListSkill from '@/views/skill/ListSkill.vue'
import AddSkill from '@/views/skill/CreateSkill.vue'
// import EditUser from '@/views/admin/user/EditUser.vue'

export default [
  {
    path: '/skill',
    name: 'skill',
    component: ListSkill,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/skill/create',
    name: 'create-skill',
    component: AddSkill,
    meta: {
      requiresAuth: true
    },
  },
]
