<template>
    <div>
        <section class="container">
            <section v-if="requirement" class="hero is-link">
                <div class="hero-body">
                    <h1>Add</h1>
                </div>
            </section>
            <div class="form-container">
                <div v-if="state.success" class="notification is-success">
                    <button class="delete" @click="state.success = ''"></button>
                    <p>{{ state.success }}</p>
                </div>
                <div v-if="error" class="notification is-danger">
                    <button class="delete" @click="error.value = ''"></button>
                    {{ error }}
                </div>
                <form @submit.prevent="handleSubmit">
                    <div class="field">
                        <label class="label">Title</label>
                        <input type="text" class="input" v-model="form.title"
                            placeholder="Enter the title of the Requirement">
                    </div>
                    <div class="field">
                        <label class="label">Type</label>
                        <input type="text" class="input" v-model="form.type" placeholder="Enter the Type">
                    </div>
                    <div class="field">
                        <label class="label">Location</label>
                        <input type="text" class="input" v-model="form.location" placeholder="Enter the Location">
                    </div>
                    <div class="field">
                        <label class="label">City</label>
                        <input type="text" class="input" v-model="form.city" placeholder="Enter the City">
                    </div>
                    <div class="field">
                        <label class="label">State</label>
                        <input type="text" class="input" v-model="form.state" placeholder="Enter the State">
                    </div>
                    <div class="field">
                        <label class="label">Work Experience</label>
                        <input type="number" class="input" v-model="form.work_experience"
                            placeholder="Enter Work Experience">
                    </div>
                    <div class="field">
                        <label class="label">No Of Positions</label>
                        <input type="number" class="input" v-model="form.no_of_positions"
                            placeholder="Enter No Of Positions">
                    </div>
                    <div class="field">
                        <label class="label">Target Date</label>
                        <input type="date" class="input" v-model="form.target_date">
                    </div>
                    <div class="field">
                        <label class="label">Qualifications</label>
                        <input type="text" class="input" v-model="form.qualifications"
                            placeholder="Enter Qualifications">
                    </div>
                    <div class="field">
                        <label class="label">Account Manager</label>
                        <div class="control">
                            <div class="select">
                                <select v-if="state.accountManagers" v-model="form.account_manager_id">
                                    <option value="null">Select Account Manager</option>
                                    <option v-for="manager in state.accountManagers" :key="manager.id"
                                        :value="manager.id">{{ manager.name }}</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="field">
                        <label class="label" for="primary skills">Primary Skills</label>
                        <div class="control">
                            <multiselect v-if="state.skills" v-model="form.primary_skills" tag-placeholder="Add this as new Skill"
                                placeholder="Search or add a skill" label="name" track-by="id" :options="state.skills"
                                :multiple="true" :taggable="true" :loading="true" @tag="addSkills"
                                @search-change="searchSkill">
                            </multiselect>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label" for="secondary skills">Secondary Skills</label>
                        <div class="control">
                            <multiselect v-if="state.skills" v-model="form.secondary_skills" tag-placeholder="Add this as new Skill"
                                placeholder="Search or add a skill" label="name" track-by="id" :options="state.skills"
                                :multiple="true" :taggable="true" :loading="true" @tag="addSkills"
                                @search-change="searchSkill">
                            </multiselect>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label" for="status">Status</label>
                        <div class="select">
                            <select class="is-capitalized" v-model="form.status">
                                <option value="">Select Status</option>
                                <option v-for="status in state.statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <input type="submit" class="button is-primary" value="Save" />
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { reactive, ref, watch, onBeforeMount } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import Multiselect from 'vue-multiselect'
import { useUserStore } from '@/store/userStore'
import { useRequirementStore } from '@/store/requirementStore'
export default {
    components: {
        Multiselect
    },
    setup() {
        const authStore = useAuthStore()
        const router = useRouter()
        const userStore = useUserStore()
        const requirementStore = useRequirementStore()
        const error = ref('')
        const api_url = process.env.VUE_APP_ROOT_API

        const state = reactive({
            requirement: [],
            success: '',
            skills: [],
            accountManagers: [],
            statuses: []
        })

        const form = reactive({
            title: '',
            type: '',
            location: '',
            city: '',
            state: '',
            work_experience: '',
            no_of_positions: '',
            target_date: '',
            qualifications: '',
            account_manager_id: null,
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id,
            status: 1,
            primary_skills: [],
            secondary_skills: [],
            skills: [],
        })

        onBeforeMount(async () => {
            try {
                await userStore.getAccountManagers()
                state.accountManagers = userStore.accountManagers

                await requirementStore.getStatuses()
                state.statuses = requirementStore.statuses

            } catch (err) {
                error.value = 'Failed to load account managers'
            }
        })

        const searchSkill = async (skill = null) => {
            try {
                const response = await axios.get(`${api_url}/api/skill/search/${skill}`)
                state.skills = response?.data || []
                if (!response.data) {
                    addSkills(skill)
                }
            } catch (err) {
                const response = await axios.get(`${api_url}/api/skill/`)
                state.skills = response?.data.data || []
            }
        }

        const addSkills = async (newSkill) => {
            try {
                await axios.post(`${api_url}/api/skill/create`, {
                    name: newSkill,
                    master_client_id: authStore?.master_client_id,
                    client_id: authStore?.client_id
                })
            } catch (err) {
                error.value = err.response?.data?.message || 'Failed to add skill'
            }
        }

        watch(() => {
            const primarySkills = form.primary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 1
            }))
            const secondarySkills = form.secondary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 0
            }))

            form.skills = [...primarySkills, ...secondarySkills]

        }, { deep: true })

        const handleSubmit = async () => {
            try {
                const response = await axios.post(`${api_url}/api/requirement/create/`, form)
                if (response.status === 201) {
                    state.success = 'Data has been added successfully'
                    router.push('/job')
                }
            } catch (err) {
                error.value = err.response?.data?.message || 'An error occurred'
            }
        }

        return { form, handleSubmit, error, state, searchSkill, addSkills }
    }
}
</script>
