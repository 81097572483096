<template>
    <div>
        <section class="container">
            <section v-if="candidate" class="hero is-link">
                <div class="hero-body">
                    <h1>Add</h1>
                </div>
            </section>
            <div class="form-container">
                <div v-if="state.success" class="notification is-success">
                    <button class="delete" @click="state.success = ''"></button>
                    <p>{{ state.success }}</p>
                </div>
                <div v-if="error" class="notification is-danger">
                    <button class="delete" @click="error.value = ''"></button>
                    {{ error }}
                </div>
                <form @submit.prevent="handleSubmit">
                    <div class="columns">
                        <div class="column">
                            <!-- <div class="field">
                                <label class="label">Name</label>
                                <input type="text" class="input" v-model="form.name"
                                    placeholder="Enter the name of the Candidate">
                            </div> -->
                            <div class="field">
                                <label class="label">First Name</label>
                                <input type="text" class="input" v-model="form.first_name"
                                    placeholder="Enter the First Name">
                            </div>
                            <div class="field">
                                <label class="label">Middle Name</label>
                                <input type="text" class="input" v-model="form.middle_name"
                                    placeholder="Enter the Middle Name">
                            </div>
                            <div class="field">
                                <label class="label">Last Name</label>
                                <input type="text" class="input" v-model="form.last_name"
                                    placeholder="Enter the Last Name">
                            </div>
                            <div class="field">
                                <label class="label">Mobile</label>
                                <input type="text" class="input" v-model="form.mobile"
                                    placeholder="Enter the Mobile Number">
                            </div>
                            <div class="field">
                                <label class="label">Email</label>
                                <input type="email" class="input" v-model="form.email" placeholder="Enter the email">
                            </div>
                            <div class="field">
                                <label class="label">Date of birth</label>
                                <input type="date" class="input" v-model="form.date_of_birth"
                                    placeholder="Enter the email">
                            </div>
                            <div class="field">
                                <label class="label">Job Title</label>
                                <input type="text" class="input" v-model="form.job_title"
                                    placeholder="Enter the job title">
                            </div>
                            <div class="field">
                                <label class="label">Total Experience</label>
                                <input type="number" class="input" v-model="form.total_experience"
                                    placeholder="Enter Total Experience">
                            </div>
                            <div class="field">
                                <label class="label">Relevant Experience</label>
                                <input type="number" class="input" v-model="form.relevant_experience"
                                    placeholder="Enter relevant experience">
                            </div>
                            <div class="field">
                                <label class="label">Current Company</label>
                                <input type="text" class="input" v-model="form.current_company"
                                    placeholder="Enter the current company">
                            </div>
                            <div class="field">
                                <label class="label">Current Work Location</label>
                                <input type="text" class="input" v-model="form.current_work_location"
                                    placeholder="Enter the current work location">
                            </div>
                            <div class="field">
                                <label class="label">Preferred Work Location</label>
                                <input type="text" class="input" v-model="form.preferred_work_location"
                                    placeholder="Enter the preferred work location">
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Notice Period</label>
                                <input type="text" class="input" v-model="form.notice_period"
                                    placeholder="Enter the notice period">
                            </div>
                            <div class="field">
                                <label class="label">Availability</label>
                                <input type="text" class="input" v-model="form.availability"
                                    placeholder="Enter the availability">
                            </div>
                            <div class="field">
                                <label class="label">Current CTC</label>
                                <input type="number" class="input" v-model="form.current_ctc"
                                    placeholder="Enter the Current CTC">
                            </div>
                            <div class="field">
                                <label class="label">Expected CTC</label>
                                <input type="number" class="input" v-model="form.expected_ctc"
                                    placeholder="Enter the Expected CTC">
                            </div>
                            <div class="field">
                                <label class="label">Address</label>
                                <input type="text" class="input" v-model="form.address" placeholder="Enter the address">
                            </div>
                            <div class="field">
                                <label class="label">City</label>
                                <input type="text" class="input" v-model="form.city" placeholder="Enter the city">
                            </div>
                            <div class="field">
                                <label class="label">State</label>
                                <input type="text" class="input" v-model="form.state" placeholder="Enter the State">
                            </div>
                            <div class="field">
                                <label class="label">Country</label>
                                <input type="text" class="input" v-model="form.country" placeholder="Enter the country">
                            </div>
                            <div class="field">
                                <label class="label">Pin</label>
                                <input type="text" class="input" v-model="form.pin" placeholder="Enter the pin">
                            </div>
                            <div class="field">
                                <label class="label">Qualifications</label>
                                <input type="text" class="input" v-model="form.qualifications"
                                    placeholder="Enter Qualifications">
                            </div>
                            <div class="field">
                                <label class="label" for="primary skills">Primary Skills</label>
                                <div class="control">
                                    <multiselect v-if="state.skills" v-model="form.primary_skills"
                                        tag-placeholder="Add this as new Skill" placeholder="Search or add a skill"
                                        label="name" track-by="id" :options="state.skills" :multiple="true"
                                        :taggable="true" :loading="true" @tag="addSkills" @search-change="searchSkill">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="secondary skills">Secondary Skills</label>
                                <div class="control">
                                    <multiselect v-if="state.skills" v-model="form.secondary_skills"
                                        tag-placeholder="Add this as new Skill" placeholder="Search or add a skill"
                                        label="name" track-by="id" :options="state.skills" :multiple="true"
                                        :taggable="true" :loading="true" @tag="addSkills" @search-change="searchSkill">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Resume</label>
                                <div class="file has-name">
                                    <label class="file-label">
                                        <input class="file-input" type="file" @change="handleFileUpload"
                                            accept=".pdf,.doc,.docx" />
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload"></i>
                                            </span>
                                            <span class="file-label">Choose a file…</span>
                                        </span>
                                        <span class="file-name">{{ form.resume ? form.resume.name : 'No file chosen'
                                            }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Photo</label>
                                <div class="file has-name">
                                    <label class="file-label">
                                        <input class="file-input" type="file" @change="handleAvatarUpload"
                                            accept="image/*" />
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload"></i>
                                            </span>
                                            <span class="file-label">Choose an image…</span>
                                        </span>
                                        <span class="file-name">{{ form.candidate_image ? form.candidate_image.name : 'No file chosen'
                                            }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="field">
                        <div class="control">
                            <input type="submit" class="button is-primary" value="Save" />
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { reactive, ref, watch, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import Multiselect from 'vue-multiselect'
import { useUserStore } from '@/store/userStore'

export default {
    components: {
        Multiselect
    },
    setup() {
        const authStore = useAuthStore()
        const router = useRouter()
        const userStore = useUserStore()
        const error = ref('')
        const api_url = process.env.VUE_APP_ROOT_API

        const state = reactive({
            candidate: [],
            success: '',
            skills: [],
            accountManagers: []
        })

        const form = reactive({
            // name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            mobile: '',
            email: '',
            job_title: '',
            total_experience: '',
            relevant_experience: '',
            current_company: null,
            current_work_location: '',
            preferred_work_location: '',
            notice_period: '',
            availability: '',
            current_ctc: '',
            expected_ctc: '',
            address: '',
            city: '',
            state: '',
            country: '',
            pin: '',
            date_of_birth: '',
            master_client_id: authStore?.master_client_id,
            client_id: authStore?.client_id,
            status: 1,
            primary_skills: [],
            secondary_skills: [],
            skills: [],
            resume: null,
            candidate_image: null
        })

        onMounted(async () => {
            try {
                await userStore.getAccountManagers()
                state.accountManagers = userStore.accountManagers
            } catch (err) {
                error.value = 'Failed to load account managers'
            }
        })

        const searchSkill = async (skill = null) => {
            try {
                const response = await axios.get(`${api_url}/api/skill/search/${skill}`)
                state.skills = response?.data || []
                if (!response.data) {
                    addSkills(skill)
                }
            } catch (err) {
                const response = await axios.get(`${api_url}/api/skill/`)
                state.skills = response?.data.data || []
            }
        }

        const addSkills = async (newSkill) => {
            try {
                await axios.post(`${api_url}/api/skill/create`, {
                    name: newSkill,
                    master_client_id: authStore?.master_client_id,
                    client_id: authStore?.client_id
                })
            } catch (err) {
                error.value = err.response?.data?.message || 'Failed to add skill'
            }
        }

        watch(() => {
            const primarySkills = form.primary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 1
            }))
            const secondarySkills = form.secondary_skills?.map(skillId => ({
                skill_id: skillId.id,
                is_primary: 0
            }))

            form.skills = [...primarySkills, ...secondarySkills]

        }, { deep: true })

        const handleFileUpload = (event) => {
            form.resume = event.target.files[0]
        }

        const handleAvatarUpload = (event) => {
            form.candidate_image = event.target.files[0]
        }

        const handleSubmit = async () => {
            try {
                const formData = new FormData()
                Object.entries(form).forEach(([key, value]) => {
                    if (key === 'resume') {
                        formData.append(key, value, value.name)
                    } else if (key === 'candidate_image') {
                        formData.append(key, value, value.name)
                    } else {
                        formData.append(key, value)
                    }
                })
                const response = await axios.post(`${api_url}/api/candidate/create/`,
                    form,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                if (response.status === 201) {
                    state.success = 'Data has been added successfully'
                    router.push('/candidate')
                }
            } catch (err) {
                error.value = err.response?.data?.message || 'An error occurred'
            }
        }

        return {
            form,
            handleSubmit,
            error,
            state,
            searchSkill,
            addSkills,
            handleFileUpload,
            handleAvatarUpload
        }
    }
}
</script>
