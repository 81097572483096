<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="masterClient" class="hero is-link">
                    <div class="hero-body">
                        <p class="title">{{ masterClient.name }}</p>
                    </div>
                </section>
                <div class="table-container">
                    <table class="table is-bordered is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{{ masterClient.name }}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{{ masterClient.mobile }}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{ masterClient.email }}</td>
                            </tr>
                            <tr>
                                <th>Contact Person</th>
                                <td>{{ masterClient.contactPerson }}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{{ masterClient.addressLine1 }}, {{ masterClient.addressLine2 }}</td>
                            </tr>
                            <tr>
                                <th>City</th>
                                <td>{{ masterClient.city }}</td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>{{ masterClient.state }}</td>
                            </tr>
                            <tr>
                                <th>Pin</th>
                                <td>{{ masterClient.pin }}</td>
                            </tr>
                            <tr>
                                <th>GST</th>
                                <td>{{ masterClient.gst }}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td v-if="masterClient.status">Active</td>
                                <td v-else>In Active</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import axios from 'axios'
// import { useMasterClientStore } from '../../../store/masterClientStore'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        // const code = route.params.code
        const api_url = process.env.VUE_APP_ROOT_API
        // const masterClientStore = useMasterClientStore()
        const masterClient = ref([])
        onMounted(async () => {
            try {
                const response = await axios.get(`${api_url}/api/admin/master-client/${route.params.code}`)
                masterClient.value = response.data
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        })

        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }

        return { masterClient }
    }
}
</script>