<template>
    <div>
        <section class="container" v-if="requirement">
            <hr>
            <section>
                <div class="columns is-multiline">
                    <div class="column">
                        <div class="box">
                            <span class="is-5">Job Title</span>
                            <h1 class="title is-4 mb-2">{{ requirement?.title }}</h1>
                            <hr>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Type</b></span>
                                    <p class="is-6 mt-2">{{ requirement.type }}</p>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Location</b></span>
                                    <p class="is-6 mt-2">{{ requirement.location }}, {{ requirement.city }}, {{
                                        requirement.state }}</p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Primary Skills</b></span>
                                    <div class="tags">
                                        <span v-for="skill in requirement.primary_skills" :key="skill.id" class="tag">{{
                                            skill.name }}</span>
                                    </div>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Secondary Skills</b></span>
                                    <div class="tags">
                                        <span v-for="skill in requirement.secondary_skills" :key="skill.id"
                                            class="tag">{{ skill.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Work Experience</b></span>
                                    <p class="is-6 mt-2">{{ requirement.work_experience }}</p>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>No Of Positions</b></span>
                                    <p class="is-6 mt-2">{{ requirement.no_of_positions }}</p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Target Date</b></span>
                                    <p class="is-6 mt-2">{{ requirement.target_date }}</p>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Qualifications</b></span>
                                    <p class="is-6 mt-2">{{ requirement.qualifications }}</p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Account Manager</b></span>
                                    <p class="is-6 mt-2">{{ requirement.account_manager_details?.name }}</p>
                                </div>
                                <!-- <div class="column">
                                    <span class="is-3 mb-2"><b>Qualifications</b></span>
                                    <p class="is-6 mt-2">{{ requirement.qualifications }}</p>
                                </div> -->
                            </div>
                        </div>                        
                    </div>
                    <div class="column">
                        <div class="tabs">
                            <ul>
                                <li :class="{ 'is-active': state?.customer?.suggestions }">
                                    <a @click="showTabsView('suggestions')"
                                        title="Suggested candidate based on skillset">Suggestions</a>
                                </li>
                                <li :class="{ 'is-active': state?.customer?.assigned }">
                                    <a @click="showTabsView('assigned')">Assigned ({{ requirement?.assigned_candidates_count }})</a>
                                </li>
                                <li :class="{ 'is-active': state?.customer?.shortlisted }">
                                    <a @click="showTabsView('shortlisted')">Shortlisted ({{ requirement?.shortlisted_candidates_count }})</a>
                                </li>
                                <li :class="{ 'is-active': state?.customer?.hired }">
                                    <a @click="showTabsView('hired')">Hired ({{ requirement?.hired_candidates_count }})</a>
                                </li>
                            </ul>
                        </div>
                        <div class="container mt-4 mb-4">
                            <div class="field">
                                <div class="control">
                                    <input type="text" v-model="filter.name" class="input" />
                                </div>
                            </div>
                        </div>
                        <div v-if="activeTab === 'assigned'">                            
                            <ListCandidate v-if="candidateStore.candidates.length" 
                                title="Assigned Candidates"
                                :requirement_id="state.requirementId"
                                :candidates="candidateStore.candidates" 
                                :pagination="candidateStore.pagination"
                                status="Shortlist" statusId=2 @page-changed="onPageChanged"
                                @assign-candidate="assignCandidate"></ListCandidate>
                        </div>
                        <div v-if="activeTab === 'suggestions'">
                            <ListCandidate v-if="candidateStore.candidates.length" title="Suggested Candidates"
                                :requirement_id="state.requirementId"    
                                :candidates="candidateStore.candidates" :pagination="candidateStore.pagination"
                                status="Assign" statusId=1 @page-changed="onPageChanged"
                                @assign-candidate="assignCandidate" />
                        </div>
                        <div v-if="activeTab === 'shortlisted'">
                            <ListCandidate v-if="candidateStore.candidates.length" title="Shortlisted Candidates"
                                :candidates="candidateStore.candidates" :pagination="candidateStore.pagination"
                                status="Hired" statusId=3 @page-changed="onPageChanged"
                                @assign-candidate="assignCandidate" />
                        </div>
                        <div v-if="activeTab === 'hired'">
                            <ListCandidate v-if="candidateStore.candidates.length" title="Hired Candidates"
                                :candidates="candidateStore.candidates" :pagination="candidateStore.pagination"
                                statusId=4 @page-changed="onPageChanged" 
                                @assign-candidate="assignCandidate" />
                        </div>
                    </div>
                </div>
            </section>
            <hr>
            
        </section>

    </div>
</template>
<script>
import { ref, onMounted, reactive, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import ListCandidate from '@/components/common/candidate/list.vue';
import { useRequirementStore } from '@/store/requirementStore'
// import ListPagination from '@/components/common/listPagination.vue';
import { useRequirementCandidateStore } from '@/store/requirmentCandidateStore'

export default {
    components: {
        ListCandidate
    },
    setup() {
        const candidateStore = useRequirementCandidateStore()
        const state = reactive({
            title: 'farook',
            customer: {
                assigned: false,
                suggestions: true,
                shortlisted: false,
                hired: false
            },
            current_page: null,
            requirementId: null,
            candidateFilter: {
                status: null
            },
            toggleModalForFollowUp: null
        });

        const filter = reactive({
            name: null
        })

        const requirementStore = useRequirementStore()

        const route = useRoute();
        const api_url = process.env.VUE_APP_ROOT_API;
        const requirement = ref();

        const fetchSuggestedCandidates = async (id, page = 1) => {
            await candidateStore.fetchSuggestedCandidates(id, page);
        };

        const fetchCandidates = async (status, page = 1) => {
            state.candidateFilter.status = status
            candidateStore.fetchCandidates(
                state.requirementId,
                state.candidateFilter, page)
        };

        const onPageChanged = (page) => {
            console.log(page);

            if (activeTab.value === 'suggestions') {
                fetchSuggestedCandidates(state.requirementId, page);
            } else if (activeTab.value === 'assigned') {
                fetchCandidates(1, page);
            } else if (activeTab.value === 'shortlisted') {
                fetchCandidates(2, page);
            } else if (activeTab.value === 'hired') {
                fetchCandidates(3, page);
            }
        };

        const assignCandidate = async (candidate, jobStatusId, statusId) => {
            try {
                const data = {
                    requirement_id: state.requirementId,
                    candidates: [
                        {
                            id: candidate.id,
                            relevant_experience: candidate.relevant_experience,
                            status: jobStatusId
                        }
                    ]
                };
                console.log(data);
                const res = await axios.post(`${api_url}/api/requirement/assign-candidates`, data);
                console.log(statusId);
                if(res.data)
                {
                    if (statusId == 1) {
                        await fetchSuggestedCandidates(state.requirementId, candidateStore.pagination.current_page)
                    } else {
                        await fetchCandidates(statusId - 1, candidateStore.pagination.current_page);
                    }
                }
                
            } catch (err) {
                console.error('Failed to assign candidate:', err);
            }
        };

        onMounted(async () => {
            try {
                await requirementStore.getRequirementBySlug(route.params.code)
                requirement.value = requirementStore.requirement
                state.requirementId = requirement.value.id
                await fetchSuggestedCandidates(requirement.value.id, 1);
            } catch (err) {
                console.error('Failed to fetch requirement:', err);
            }
        });

        const showTabsView = (tab) => {
            Object.keys(state.customer).forEach((key) => (state.customer[key] = false));
            state.customer[tab] = true;
        };

        const activeTab = computed(() => {
            return Object.keys(state.customer).find((key) => state.customer[key]) || 'assigned';
        });

        watch(activeTab, async (candidate) => {
            if (candidate === 'suggestions') {
                candidateStore.candidates = []
                await fetchSuggestedCandidates(requirement.value.id, 1);
            }
            if (candidate === 'assigned') {
                candidateStore.candidates = []
                await fetchCandidates(1);
            }
            if (candidate === 'shortlisted') {
                candidateStore.candidates = []
                await fetchCandidates(2);
            }
            if (candidate === 'hired') {
                candidateStore.candidates = []
                await fetchCandidates(3);
            }
        });

        return { 
            requirement, 
            state, 
            showTabsView, 
            activeTab, 
            assignCandidate, 
            onPageChanged, 
            candidateStore,
            filter
        };
    }
};
</script>
