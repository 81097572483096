import ListViewClient from '@/views/admin/client/ListViewClient.vue'
import ViewClient from '@/views/admin/client/ViewClient.vue'
import AddClient from '@/views/admin/client/AddClient.vue'
import EditClient from '@/views/admin/client/EditClient.vue'

export default [
  {
    path: '/client',
    name: 'client',
    component: ListViewClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/client/view/:code',
    name: 'view-client',
    component: ViewClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/client/add',
    name: 'add-client',
    component: AddClient,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/client/edit/:code',
    name: 'edit-client',
    component: EditClient,
    meta: {
      requiresAuth: true
    },
  }
]
