import ListRole from '@/views/admin/role/ListRole.vue'
import AddRole from '@/views/admin/role/CreateRole.vue'
// import EditUser from '@/views/admin/user/EditUser.vue'

export default [
  {
    path: '/role',
    name: 'role',
    component: ListRole,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/role/create',
    name: 'create-role',
    component: AddRole,
    meta: {
      requiresAuth: true
    },
  },
]
