<template>
    <div>
        <aside class="menu left-menu">
            <figure class="image">
                <img src="../../assets/images/logo.png" alt="Logo" srcset="">
            </figure>
            <!-- <p class="menu-label">General</p> -->
            <ul class="menu-list">                
                <li v-for="item in availableMenuItems" :key="item.path">
                    <router-link :to="item.path" active-class="is-active">{{ item.name }}</router-link>
                </li>
            </ul>
        </aside>
    </div>
</template>
<script>
import { useAuthStore } from '@/store/auth'
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router'
export default {
    setup() {
        const authStore = useAuthStore()
        const route = useRoute()

        const state = reactive({
            menuItem: [
                { path: '/dashboard', name: 'Dashboard' },
                { path: '/job', name: 'Jobs' },
                { path: '/candidate', name: 'Candidate' },
                { path: '/master-client', name: 'Master Client', roles: [1] },
                { path: '/client', name: 'Client', roles: [1, 2] },
                { path: '/skill', name: 'Skill' },
                { path: '/role', name: 'Role', roles: [1] },
                { path: '/user', name: 'User', roles: [1, 2, 3] }
            ],
        })

        const availableMenuItems = computed(() => {
            return state.menuItem.filter(item => {
                if(item.roles) {
                    return item.roles.includes(authStore.role_id)
                }
                return true
            })
        })

        const isActive = (path) => {
            return route.path === path
        }

        return { authStore, isActive, state, availableMenuItems }
    }
}
</script>