<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="user" class="hero is-link">
                    <div class="hero-body">
                        <h1>Add</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="field">
                            <div class="label">First Name</div>
                            <input type="text" class="input" v-model="form.first_name" placeholder="Enter the name of the User">
                        </div>
                        <div class="field">
                            <div class="label">Middle Name</div>
                            <input type="text" class="input" v-model="form.middle_name" placeholder="Enter the name of the User">
                        </div>
                        <div class="field">
                            <div class="label">Last Name</div>
                            <input type="text" class="input" v-model="form.last_name" placeholder="Enter the name of the User">
                        </div>
                        <div class="field">
                            <div class="label">Email</div>
                            <input type="email" class="input" v-model="form.email" placeholder="Enter the email of the User">
                        </div>
                        <div class="field">
                            <div class="label">password</div>
                            <input type="password" class="input" v-model="form.password" placeholder="Enter the password">
                        </div>
                        <div class="field">
                            <div class="label">Confirmation Password</div>
                            <input type="password" class="input" v-model="form.password_confirmation" placeholder="Enter the password">
                        </div>
                        <div class="field">
                            <div class="label">Role</div>
                            <div class="select">
                                <select v-model="form.role_id" v-if="state.roles">
                                    <option value="">Select Role</option>
                                    <option v-for="role in state.roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                                </select>
                            </div>
                        </div>
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref, onBeforeMount } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useRoleStore } from '../../../store/roleStore'
export default {
    setup() {
        const roleStore = useRoleStore()
        
        const state = reactive ({
            user: [],
            success: '',
            roles: null
        })
        
        const form = reactive({
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role_id: '',
            name: null
        })

        // const fullName = computed(() => {
        //     return `${form.first_name ? form.first_name + ' ' : ''}
        //             ${form.middle_name ? form.middle_name + ' ' : ''}
        //             ${form.last_name}`;
        // })

        const route = useRoute()
        const router = useRouter()
        const api_url = process.env.VUE_APP_ROOT_API
        const error = ref()

        onBeforeMount (async () => {
            await roleStore.getRoles()
            state.roles = roleStore.role
        })

        const  handleSubmit = async () => {
            try {
                console.log(route.params.code);
                // form.name = fullName
                const response = await axios.post(`${api_url}/api/admin/user/create/`, form)
                if(response.status == 201)
                {
                    state.success = 'Data has been added successfully'
                    router.push('/user')
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Role:', error)
            }
        }

        return { form, handleSubmit, error, state }
    }
}
</script>