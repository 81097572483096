<template>
    <div>
        <section class="container">
            <section class="container">
                <section v-if="user" class="hero is-link">
                    <div class="hero-body">
                        <h1>Edit</h1>
                    </div>
                </section>
                <div class="form-container">
                    <div v-if="state.success" class="notification is-success">
                        <button class="delete"></button>
                        <p>{{ state.success }}</p>
                    </div>
                    <div v-if="error" class="notification is-danger">
                        <button class="delete"></button>
                        {{ error }}
                    </div>
                    <form @submit.prevent="handleSubmit">
                        <div class="field">
                            <div class="label">Name</div>
                            <input type="text" class="input" v-model="form.name" placeholder="Enter the name of the user">
                        </div>
                        <div class="field">
                            <div class="label">Mobile</div>
                            <input type="text" class="input" v-model="form.mobile" placeholder="Enter mobile number">
                        </div>
                        <div class="field">
                            <div class="label">Email</div>
                            <input type="text" class="input" v-model="form.email" placeholder="Enter email">
                        </div>
                        <hr>
                        <div class="field">
                            <input type="submit" class="button is-primary" value="Save"/>
                        </div>
                    </form>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const state = reactive ({
            user: [],
            success: ''
        })
        const form = reactive({
            name: '',
            mobile: '',
            email: '',
        })
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        // const user = ref([])
        const error = ref()
        // const success = ref()
        onMounted(async () => {
            try {
                const response = await axios.get(`${api_url}/api/admin/user/${route.params.code}`)
                state.user = response.data
                form.name = state.user.name
                form.mobile = state.user.mobile
                form.email = state.user.email
            }
            catch (error) {
                console.error('Failed to fetch Master User:', error)
            }
        })

        const  handleSubmit = async () => {
            try {
                console.log(route.params.code);
                const response = await axios.put(`${api_url}/api/admin/user/update/${route.params.code}`, form)
                if(response.status == 200)
                {
                    state.success = 'Data has been updated successfully'
                }
                console.log(response);
            }
            catch (error) {
                error.value = error
                console.error('Failed to fetch Master user:', error)
            }
        }

        return { form, handleSubmit, error, state }
    }
}
</script>