<template>
    <div class="modal" v-bind:class="{ 'is-active': state.toggleModalForImport }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Import Candidates</p>
                <button class="delete" aria-label="close"
                    @click="state.toggleModalForImport = !state.toggleModalForImport"></button>
            </header>
            <section class="modal-card-body">
                <div class="notification is-success" v-if="state.message">
                    <button class="delete" @click="state.message = !state.message"></button>
                    {{ state.message }}
                </div>
                <div class="notification is-danger" v-if="state.error">
                    <button class="delete" @click="state.error = !state.error"></button>
                    {{ state.error }}
                </div>
                <div class="file is-boxed">
                    <label class="file-label">
                        <input class="file-input" type="file" @change="handleImportFile" />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label"> Choose a file… </span>
                        </span>
                        <span class="file-name">{{ form.import_candidate ? 
                            form.import_candidate.name : 'No file chosen'
                            }}</span>
                    </label>
                </div>
                <progress v-show="state.progress" class="progress is-success" :value="state.importProgress"
                    max="100">
                    {{ state.importProgress }}
                </progress>
            </section>
            <footer class="modal-card-foot is-justify-content-end">
                <div class="buttons">

                    <button class="button"
                        @click="state.toggleModalForImport = !state.toggleModalForImport">Cancel</button>
                    <button class="button is-success" @click="handleImportCandidate">Import</button>
                </div>
            </footer>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch } from 'vue';
import axios from 'axios'

export default defineComponent({
    name: 'CandidateImport',
    props: {
        toggleModalForImport: {
            type: Boolean,
            required: true
        },
        authStore: {
            type: Array,
            required: true
        },
    },
    setup(props) {
        const api_url = process.env.VUE_APP_ROOT_API;
        const state = reactive({
            importProgress: 0,
            message: null,
            error: null,
            toggleModalForImport: false
        })
        const form = reactive({
            import_candidate: null,
            master_client_id: null,
            client_id: null
        })

        const handleImportFile = (event) => {
            form.import_candidate = event.target.files[0]
        }

        const handleImportCandidate = async () => {
            try {
                const formData = new FormData()
                Object.entries(form).forEach(([key, value]) => {
                    if (key === 'import_candidate') {
                        formData.append(key, value, value.name)
                    } else {
                        formData.append(key, value)
                    }
                })
                state.progress = true;
                const response = await axios.post(`${api_url}/api/candidate/import/`,
                    form,
                    {
                        onUploadProgress: (progressEvent) => {
                            var progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            state.importProgress = progress
                            console.log(`Upload Progress: ${progress}%`);
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                if (response.status == 200) {
                    state.message = response.data.message
                }
            } catch (err) {
                state.error = err.response?.data?.message || 'An error occurred'
            }
        }

        watch(props, async () => {
            state.toggleModalForImport = props.toggleModalForImport
            state.master_client_id = props.authStore?.master_client_id
            state.client_id = props.authStore?.client_id
        }, 300)

        return { 
            state, 
            form,
            handleImportCandidate, 
            handleImportFile, 
            ...toRefs(props) 
        };
    }

})
</script>