import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API

export const useRoleStore = defineStore('roleStore', {
    state: () => ({
        name: 'Role Test',
        role: [],
        loading: false,
        current_page: 1,
        code: null,
    }),
    actions: {
        async getRoles() {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/admin/role`)
                this.role = response.data
                // this.current_page = response.data.current_page
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getRoleBySlug(id) {
            try {
                this.loading = true
                const response = await axios.get(`${api_url}/api/admin/role/${id}`)
                // console.log(response.data);
                this.role = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Role:', error)
            }
        },
        // async addClient(client) {
        //     try {
        //         const response = await axios.post(`${api_url}/api/client/client/create`, client)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async updateClient(client) {
        //     try {
        //         const response = await axios.put(`${api_url}/api/client/client/update/${client.slug}`, client)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async deleteClient(clientId) {
        //     try {

        //     }
        // }
    }
})