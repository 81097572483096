<template>
    <div>
        <section class="container">
            <section class="hero is-link">
                <div class="hero-body">
                    <p class="title">User</p>
                </div>
            </section>
            <hr>
            <!-- {{ maserClients.data }} -->
              <div v-if="userStore.loading">
                <p>Data is loading....</p>
              </div>
            <section class="container">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <p>User lists</p>
                        </div>
                    </div>
                    <div class="level-right">
                        <p class="level-item"><router-link :to="'/user/create/'" class="button is-primary">Add</router-link></p>
                    </div>
                </nav>
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="userStore.user">
                        <tr v-for="(user, index) in userStore.user" :key="user.id">
                            <td>{{ serialNumber(index, userStore.pagination.current_page) }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.mobile }}</td>
                            <td>
                                <div class="buttons">
                                    <router-link :to="'/user/view/' + user.slug" class="button is-info">View</router-link>
                                    <!-- <button class="button is-info">View</button> -->
                                    <!-- <button class="button is-warning">Edit</button> -->
                                    <router-link :to="'/user/edit/' + user.slug" class="button is-warning">Edit</router-link>
                                    <button class="button is-danger">Delete</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ListPagination
                    v-if="userStore.pagination"
                    :links="userStore.pagination.links"
                    :current_page="userStore.pagination.current_page"
                    :last_page="userStore.pagination.last_page"
                    @page-changed="fetchData"
                />
            </section>
        </section>        
    </div>
</template>
<script>

import { useUserStore } from '../../../store/userStore'
import ListPagination from '@/components/common/listPagination.vue';
export default {
    components: {
        ListPagination
    },
    setup() {
        const userStore = useUserStore()
        userStore.getUsers()
        const fetchData = async (page) => {
            await userStore.getUsers(page)
        }
        const  serialNumber = (index, current_page) => {
            return ((current_page - 1) * 10) + (index + 1)
        }
        return { userStore, serialNumber, fetchData }
    }
}
</script>