<template>
    <div>
        <section class="container">
            <section class="container">
                <div class="columns">
                    <div class="column">
                        <div class="box" v-if="user">
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Name</b></span>
                                    <p class="is-6 mt-2">{{ user.name }}</p>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Role</b></span>
                                    <p class="is-6 mt-2">{{ user.role?.name }}</p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Mobile</b></span>
                                    <p class="is-6 mt-2">{{ user.mobile }}</p>
                                </div>
                                <div class="column">
                                    <span class="is-3 mb-2"><b>Email</b></span>
                                    <p class="is-6 mt-2">{{ user.email }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="tabs">
                            <ul>
                                <li :class="{ 'is-active': state?.job?.suggestions }">
                                    <a @click="showTabsView('suggestions')">Suggestions</a>
                                </li>
                                <li :class="{ 'is-active': state?.job?.assigned }">
                                    <a @click="showTabsView('assigned')">Assigned</a>
                                </li>
                                <li :class="{ 'is-active': state?.job?.inProgress }">
                                    <a @click="showTabsView('inProgress')">In Progress</a>
                                </li>
                                <li :class="{ 'is-active': state?.job?.hired }">
                                    <a @click="showTabsView('hired')">Done</a>
                                </li>
                            </ul>                            
                        </div>
                        <div v-if="activeTab === 'suggestions'">
                            <ListJob v-if="recruiterStore.jobs.length" title="Suggested Jobs"
                            :jobs="recruiterStore.jobs" :pagination="recruiterStore.pagination"
                            status="Assign" statusId=1 @page-changed="onPageChanged"
                            @assign-job="ListJob" />
                        </div>
                    </div>                    
                </div>
                <!-- <section v-if="user" class="hero is-link">
                    <div class="hero-body">
                        <p class="title">{{ user.name }}</p>
                    </div>
                </section> -->
                <!-- <div class="table-container">
                    <table class="table is-bordered is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{{ user.name }}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{{ user.mobile }}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{ user.email }}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td v-if="user.status">Active</td>
                                <td v-else>In Active</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </section>
        </section>        
    </div>
</template>
<script>
/* eslint-disable */
import { ref, onMounted, computed, watch, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRecruiterStore } from '@/store/recruiterStore'
import ListJob from '@/components/common/job/list.vue'

export default {
    components: {
        ListJob
    },
    setup() {
        const route = useRoute()
        const api_url = process.env.VUE_APP_ROOT_API
        const user = ref([])

        const recruiterStore = useRecruiterStore()

        const state = reactive({
            job: {
                assigned: false,
                suggestions: true,
                inProgress: false,
                hired: false
            },
            recruiterId: null
        })

        onMounted(async () => {
            try {
                const response = await axios.get(`${api_url}/api/admin/user/${route.params.code}`)
                user.value = response.data
                state.recruiterId = response.data.id
                await fetchSuggestedJobs(user.value.id, 1);
            }
            catch (error) {
                console.error('Failed to fetch Master User:', error)
            }
        })

        const activeTab = computed(() => {
            return Object.keys(state.job).find((key) => state.job[key]) || 'assigned';
        });

        const fetchSuggestedJobs = async (id, page = 1) => {
            await recruiterStore.fetchSuggestedJobs(id, page);
        };

        watch(activeTab, async (job) => {
            if (job === 'suggestions') {
                recruiterStore.jobs = []
                await fetchSuggestedJobs(user.value.id, 1);
            }
            if (job === 'assigned') {
                recruiterStore.jobs = []
                // await fetchJobs(1);
            }
            if (job === 'shortlisted') {
                recruiterStore.jobs = []
                // await fetchJobs(2);
            }
            if (job === 'hired') {
                recruiterStore.jobs = []
                // await fetchJobs(3);
            }
        });

        const onPageChanged = (page) => {
            console.log(page);

            if (activeTab.value === 'suggestions') {
                fetchSuggestedJobs(state.recruiterId, page);
            } else if (activeTab.value === 'assigned') {
                fetchJobs(1, page);
            } else if (activeTab.value === 'shortlisted') {
                fetchJobs(2, page);
            } else if (activeTab.value === 'hired') {
                fetchJobs(3, page);
            }
        };

        const showTabsView = (tab) => {
            Object.keys(state.job).forEach((key) => (state.job[key] = false));
            state.job[tab] = true;
        };

        return { user, state, showTabsView, recruiterStore, activeTab, onPageChanged }
    }
}
</script>